import { ChevronDownIcon, ChevronUpIcon } from '@chakra-ui/icons';
import {
    Divider,
    Flex,
    Heading,
    IconButton,
    Spacer,
    Text,
    useDisclosure,
    VStack,
} from '@chakra-ui/react';

function Question({ question, answer }) {
    const { isOpen, onOpen, onClose } = useDisclosure();
    return (
        <VStack w="100%" py={[3, 4]} alignItems="start">
            <Flex w="100%" direction="row" alignItems="center">
                <Text
                    fontSize={['16px', 'x-large']}
                    color={isOpen ? 'brand.green' : 'gray.400'}
                >
                    {question}
                </Text>
                <Spacer />
                <IconButton
                    isRound={true}
                    alignContent={'center'}
                    variant="solid"
                    backgroundColor="brand.red"
                    aria-label="Done"
                    fontSize="20px"
                    icon={
                        isOpen ? (
                            <ChevronUpIcon onClick={onClose} color="white" />
                        ) : (
                            <ChevronDownIcon onClick={onOpen} color="white" />
                        )
                    }
                />
            </Flex>
            {isOpen && (
                <Text
                    color="brand.green"
                    fontSize={['14px', 'medium']}
                    textAlign="left"
                >
                    {answer}
                </Text>
            )}
        </VStack>
    );
}

function FaQ() {
    return (
        <VStack
            backgroundColor="brand.red"
            w="100%"
            pt={[12, 24]}
            px={[4, 24]}
            alignItems={['center', 'start']}
            height={[750, 1000]}
        >
            <Heading fontSize={[24, 80]} as="h1" color="white">
                FAQ
            </Heading>
            <Text
                fontSize={['medium', 'x-large']}
                color="white"
                textAlign={['center', 'left']}
            >
                Explore activities and make a difference
            </Text>
            <VStack
                backgroundColor="white"
                p={[2, 4]}
                px={[4, 4]}
                alignSelf="stretch"
                mt={[14, 30]}
                overflow={'auto'}
                height={[550, 700]}
                fontSize={['medium', 'large']}
            >
                <Question
                    question="How do I earn points in this loyalty program?"
                    answer={
                        <Flex direction="column">
                            <Text>
                                The number of points you earn depends on what
                                you're buying:
                            </Text>
                            <VStack pl="2em" align="start">
                                <ol>
                                    <li>
                                        <Text>
                                            For booking an escape room
                                            experience:
                                        </Text>
                                        <VStack pl="2em" align="start">
                                            <ul>
                                                <li>
                                                    As an Organizer: You earn 50
                                                    points per USD spent (earned
                                                    at check in)
                                                </li>
                                                <li>
                                                    As a Participant: You earn
                                                    25 points per USD spent
                                                    (earned at check in)
                                                </li>
                                            </ul>
                                        </VStack>
                                    </li>
                                    <li>
                                        <Text>For merchandise purchases:</Text>
                                        <VStack pl="2em" align="start">
                                            <ul>
                                                <li>
                                                    You earn 10 points per USD
                                                    spent
                                                </li>
                                            </ul>
                                        </VStack>
                                    </li>
                                </ol>
                            </VStack>
                            <Text>
                                Also, check the "Deals & Ways to Earn" section
                                for other point-earning opportunities.
                            </Text>
                        </Flex>
                    }
                />
                <Divider />
                <Question
                    question="How can I redeem my points for rewards?"
                    answer="You can redeem points through your account on the website. Select the reward you want and follow the prompts to complete the redemption. A promo code will be generated which can then be applied during checkout online or instore to redeem the reward."
                />
                <Divider />
                <Question
                    question="How do challenges work?"
                    answer={`Challenges are special tasks or goals you can complete to earn extra points or rewards. They're listed in the "Deals & Ways to Earn" section and change periodically.`}
                />
                <Divider />
                <Question
                    question="What kinds of rewards can I get with my points?"
                    answer="You can redeem points for various rewards, including discounts on episodes, merchandise, and special items. Check the rewards catalog for current offerings."
                />
                <Divider />
                <Question
                    question="Are there any special perks for higher tier members?"
                    answer="Yes, as you advance to higher tiers, you'll unlock additional benefits and exclusive offers. Details are available in the tier benefits section of the program."
                />
                <Divider />
                <Question
                    question="What's the difference between tier points and spendable points?"
                    answer="Tier points determine your membership level, while spendable points can be redeemed for rewards. Both are earned at the same rate through your activities."
                />
                <Divider />
                <Question
                    question="How do I advance to higher tiers?"
                    answer="You advance by accumulating tier points. The more points you earn, the higher your tier level becomes. Each tier has a specific point threshold."
                />
                <Divider />
                <Question
                    question="Do my points or tier status expire?"
                    answer="Yes, both points and tier status expire on a rolling basis. Points typically expire 13 months after they're earned, while tier status is generally maintained for 13 months from your last tier advancement."
                />
                <Divider />
                <Question
                    question="How can I check my point balance and tier status?"
                    answer="Your current points and tier information are displayed on your account dashboard when you log in."
                />
                <Divider />
                <Question
                    question="What are badges and how do I earn them?"
                    answer="Badges are virtual achievements you can earn by completing specific actions or milestones in the program. They're displayed on your profile and represent your accomplishments."
                />
                <Divider />
                <Question
                    question="Can I see my activity history?"
                    answer="Yes, your activity history is available on your account page, showing recent point earnings and redemptions."
                />
                <Divider />
                <Question
                    question="How will I know when I've earned a new badge or advanced to a new tier?"
                    answer="Earned badges will transition from the unearned gray color to the earned red color. If a badge has been earned multiple times, a count indicator will appear to reflect how many times the badge has been earned."
                />
            </VStack>
        </VStack>
    );
}

export default FaQ;
