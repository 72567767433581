import React, { useEffect, useRef } from 'react';
import {
    Container,
    Box,
    Flex,
    Text,
    Image,
    Button,
    VStack,
} from '@chakra-ui/react';
import { gsap } from 'gsap';
import { useNavigate } from 'react-router-dom';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import HowTo1 from '../../images/Badges.jpg';
import HowTo2 from '../../images/Rewards.png';
import HowTo3 from '../../images/CompleteAdventures.jpg';
import HowTo4 from '../../images/Leaderboard.jpg';
import HowTo5 from '../../images/FlashPrizes.jpg';
import HowToBar1 from '../../images/HowToBar1.svg';
import HowToBar2 from '../../images/HowToBar2.svg';
import HowToBar3 from '../../images/HowToBar3.svg';
import HowToBar4 from '../../images/HowToBar4.svg';
import { useViewport } from '../../utils';

gsap.registerPlugin(ScrollTrigger);

const sections = [
    {
        image: HowTo1,
        title: 'Badges',
        description:
            'Represents your achievements and skills in various areas. Collect them all to showcase your expertise and track your progress.',
    },
    {
        image: HowTo2,
        title: 'Rewards',
        description:
            'Represents your achievements and skills in various areas. Collect them all to showcase your expertise and track your progress.',
    },
    {
        image: HowTo3,
        title: 'Complete Episodes',
        description:
            'Represents your achievements and skills in various areas. Collect them all to showcase your expertise and track your progress.',
    },
    {
        image: HowTo4,
        title: 'Leaderboard',
        description:
            'Top the Leaderboard: Monthly Team Trials! Race the clock, outsmart rivals. Top 10 teams score big. Boost your rewards with every challenge. Ready to dominate? Join now!',
    },
    {
        image: HowTo5,
        title: 'Flash Prizes',
        description:
            "Flash Prizes: Blink and You'll Miss Out! Red Door Loyalty members, seize exclusive time-sensitive giveaways and promos. Quick action unlocks instant rewards. Ready for lightning deals?",
    },
];

const ImageBox = ({ image }) => (
    <Box w={['80%', '100%', '100%', '45%']} id="how-to-section-image">
        <Image
            w={['100%', 'auto', 'auto', 'auto']}
            maxW={['100%', 'auto', 'auto', 'auto']}
            src={image}
        />
    </Box>
);

const TextSection = ({ title, description }) => (
    <Flex
        id="how-to-section-text-container"
        direction={['column', 'column', 'column', 'column']}
        w={['100%', 'auto', 'auto', '45%']}
        alignItems={['center', 'center', 'center', 'start']}
        justifyContent="center"
        px={[2, 2, 2, 10]}
        gap={[2, 2, 2, 6]}
    >
        <Text
            color="brand.green"
            fontWeight={700}
            fontSize={['xx-large', 'xx-large', 'xx-large', 'xxx-large']}
            lineHeight="1.2"
        >
            {title}
        </Text>
        <Text
            textAlign={['center', 'center', 'center', 'left']}
            color="brand.green"
            fontWeight={600}
            fontSize={['large', 'large', 'large', 'larger']}
            my={[2, 2, 2, 0]}
        >
            {description}
        </Text>
    </Flex>
);

const HowDoesItWork = ({ showJoinBtn = true }) => {
    const navigate = useNavigate();
    const sectionRefs = useRef([]);
    const iconRefs = useRef([]);
    const titleRef = useRef(null);
    const descriptionRef = useRef(null);
    const buttonRef = useRef(null);
    iconRefs.current = [];
    const { isMobile } = useViewport();

    const addToRefs = (el) => {
        if (el && !iconRefs.current.includes(el)) {
            iconRefs.current.push(el);
        }
    };

    useEffect(() => {
        // Animation for the title
        gsap.fromTo(
            titleRef.current,
            { y: 50, opacity: 0 },
            {
                y: 0,
                opacity: 1,
                duration: 1,
                ease: 'power3.out',
                scrollTrigger: {
                    trigger: titleRef.current,
                    start: 'top 80%',
                    end: 'bottom 20%',
                    scrub: true,
                },
            }
        );

        // Animation for the description
        gsap.fromTo(
            descriptionRef.current,
            { y: 50, opacity: 0 },
            {
                y: 0,
                opacity: 1,
                duration: 1,
                ease: 'power3.out',
                delay: 0.2,
                scrollTrigger: {
                    trigger: descriptionRef.current,
                    start: 'top 80%',
                    end: 'bottom 20%',
                    scrub: true,
                },
            }
        );

        // Animation for the button
        gsap.fromTo(
            buttonRef.current,
            { scale: 0.9, opacity: 0 },
            {
                scale: 1,
                opacity: 1,
                duration: 0.8,
                ease: 'back.out(1.7)',
                delay: 0.4,
                scrollTrigger: {
                    trigger: buttonRef.current,
                    start: 'top 80%',
                    end: 'bottom 20%',
                    scrub: true,
                },
            }
        );

        iconRefs.current.forEach((icon, index) => {
            gsap.fromTo(
                icon,
                { autoAlpha: 0, y: -50 },
                {
                    autoAlpha: 1,
                    y: 0,
                    duration: 0.5,
                    ease: 'power2.out',
                    scrollTrigger: {
                        trigger: icon,
                        start: 'top 80%',
                        end: 'bottom 20%',
                        scrub: true,
                        delay: index * 0.2,
                    },
                }
            );
        });

        /*if (!isMobile)
            sectionRefs.current.forEach((section, index) => {
                // Define movement values for different screen sizes
                const xMovement = index % 2 === 0 ? -50 : 50;
                console.log(section);

                gsap.fromTo(
                    section,
                    { autoAlpha: 0, x: xMovement },
                    {
                        autoAlpha: 1,
                        x: 0,
                        duration: 1.5,
                        ease: 'power2.out',
                        scrollTrigger: {
                            trigger: section,
                            start: 'top 80%',
                            end: 'bottom 50%',
                            scrub: true,
                        },
                    }
                );
            });*/
    }, []);

    return (
        <Container
            w="100%"
            h="100%"
            maxW="100%"
            p={0}
            m={0}
            backgroundColor="white"
            id="how-does-it-work-container"
            position="relative"
        >
            <Flex
                w="100%"
                h="500px"
                alignItems={['center', 'center', 'start', 'start']}
                direction={['column', 'column', 'column', 'column']}
                id="how-does-it-work-title-section-container"
                backgroundColor="brand.red"
                py={[5, 5, 5, 28]}
                px={[2, 2, 2, 20]}
                position="relative"
            >
                <Text
                    fontWeight={700}
                    fontSize={['xx-large', 'xx-large', 'xx-large', 'xxx-large']}
                    color="white"
                    id="how-does-it-work-title"
                    ref={titleRef}
                >
                    How Does It Work?
                </Text>
                <Flex
                    w="100%"
                    direction={['column', 'column', 'row', 'row']}
                    justifyContent="space-between"
                >
                    <Text
                        ref={descriptionRef}
                        fontWeight={700}
                        fontSize={['large', 'large', 'large', 'x-large']}
                        color="white"
                        textAlign={['center', 'center', 'center', 'left']}
                        mt={[5, 5, 5, 0]}
                        id="how-does-it-work-description"
                    >
                        Explore activities and make
                        <br />a difference
                    </Text>
                    <Button
                        ref={buttonRef}
                        variant="white"
                        fontWeight={800}
                        display={showJoinBtn ? 'block' : 'none'}
                        visibility={showJoinBtn ? 'visible' : 'hidden'}
                        alignSelf={['center', 'center', 'center', 'end']}
                        mt={[5, 5, 5, 0]}
                        id="how-does-it-work-join-now"
                        size="lg"
                        _hover={{
                            bg: '#f08281',
                            transform: 'scale(1.05)',
                            transition: 'all 0.3s ease',
                        }}
                        _active={{
                            bg: '#C4201D',
                        }}
                        onClick={() => {
                            window.scrollTo(0, 0);
                            navigate('/signup');
                        }}
                    >
                        Join Now
                    </Button>
                </Flex>
            </Flex>

            <Flex
                direction="column"
                w="100%"
                p={[2, 2, 2, 20]}
                pt={[0, 0, 0, 0]}
                mt={-180}
                position="absolute"
                id="how-does-it-work-content"
            >
                <Flex
                    direction="row"
                    w="100%"
                    borderColor="gray.200"
                    borderWidth={2}
                    p={[2, 2, 2, 20]}
                    backgroundColor="white"
                    // pb={[20, 20, 20, 20]}
                >
                    <Flex
                        direction="column"
                        backgroundColor="brand.red"
                        width={['24px', '24px', '44px', '55px']}
                        height="auto"
                        justifyContent="space-around"
                        mr={[2, 2, 2, 0]}
                        id="icon-bar-container"
                    >
                        <Image ref={addToRefs} src={HowToBar1} />
                        <Image ref={addToRefs} src={HowToBar2} />
                        <Image ref={addToRefs} src={HowToBar1} />
                        <Image ref={addToRefs} src={HowToBar3} />
                        <Image ref={addToRefs} src={HowToBar4} />
                    </Flex>
                    <VStack flex={1} gap={10}>
                        {sections.map((section, index) => (
                            <Flex
                                direction={[
                                    'column',
                                    'column',
                                    'column',
                                    'row',
                                ]}
                                justifyContent={[
                                    'space-between',
                                    'center',
                                    'center',
                                    'center',
                                ]}
                                alignItems={[
                                    'center',
                                    'center',
                                    'center',
                                    'stretch',
                                ]}
                                gap={[5, 5, 5, 5]}
                                id="how-to-section-container-wrapper"
                                ref={(el) => (sectionRefs.current[index] = el)}
                                mb={10}
                                className={!isMobile ? (index % 2 === 0 ? 'section section-left' : 'section section-right') : ''}
                            >
                                {index % 2 === 0 && !isMobile ? (
                                    <ImageBox image={section.image} />
                                ) : (
                                    <TextSection
                                        title={section.title}
                                        description={section.description}
                                    />
                                )}
                                {index % 2 === 0 && !isMobile ? (
                                    <TextSection
                                        title={section.title}
                                        description={section.description}
                                    />
                                ) : (
                                    <ImageBox image={section.image} />
                                )}
                            </Flex>
                        ))}
                    </VStack>
                </Flex>
            </Flex>
        </Container>
    );
};

export default HowDoesItWork;
