import React, { useState } from 'react';
import ReactPaginate from 'react-paginate';
import { Button, Flex } from '@chakra-ui/react';
import './list-container.css';
import ActivityList from './ActivityList';

export const ListContainer = ({ items, itemsPerPage }) => {
    const [itemOffset, setItemOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);

    const endOffset = itemOffset + itemsPerPage;
    const currentItems = items.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(items.length / itemsPerPage);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % items.length;
        setItemOffset(newOffset);
        setCurrentPage(event.selected);
    };

    return (
        <>
            <ActivityList currentItems={currentItems} />
            {pageCount > 1 && (
                <Flex
                    h="80px"
                    w="100"
                    justifyContent="center"
                    border="1px solid"
                    borderColor="gray.100"
                >
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel={
                            <Button
                                size={['sm', 'sm', 'sm', 'md']}
                                variant="greenBorder"
                                isDisabled={currentPage === pageCount - 1}
                                _disabled={{
                                    opacity: 0.5,
                                    cursor: 'not-allowed',
                                }}
                            >
                                Next
                            </Button>
                        }
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={1}
                        pageCount={pageCount}
                        previousLabel={
                            <Button
                                size={['sm', 'sm', 'sm', 'md']}
                                variant="greenBorder"
                                isDisabled={currentPage === 0}
                                _disabled={{
                                    opacity: 0.5,
                                    cursor: 'not-allowed',
                                }}
                            >
                                Previous
                            </Button>
                        }
                        renderOnZeroPageCount={null}
                        className="react-paginate"
                        activeLinkClassName="active-link"
                    />
                </Flex>
            )}
        </>
    );
};

export default ListContainer;
