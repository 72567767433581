import { Container } from '@chakra-ui/react';

import React, { useContext } from 'react';

import { UserContext } from '../../UserContext';

//Components
import FaQ from '../../FaQ';
import Header from '../../Header';
import TierBenefits from '../../components/TierBenefits/TierBenefits';

function Benefits() {
    const { user } = useContext(UserContext);

    return (
        <Container w="100%" maxW="initial" p={0} backgroundColor="white">
            <Header user={user} />
            <TierBenefits user={user} />
            <FaQ />
        </Container>
    );
}

export default Benefits;
