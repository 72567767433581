import { Box, Button, Center, Flex, Image } from '@chakra-ui/react';
import { useRef } from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import whiteLeftArrow from './images/WhiteArrowLeft.svg';
import whiteRightArrow from './images/WhiteArrowRight.svg';
import SliderItem from './SliderItem';
import { useViewport } from './utils';
import { renderSlides } from './utils/renderSlides';

import rewardsQueries from './hooks/queries/rewards';

const buttonStyle = {
  display: 'none',
};

const properties = {
  prevArrow: <button id="leftBtn" style={{ ...buttonStyle }}></button>,
  nextArrow: <button id="rightBtn" style={{ ...buttonStyle }}></button>,
};

const mapSlides = (data) => {
  return data?.map(
    ({
      title,
      description,
      ends_at,
      image,
      points,
      progress,
      category,
      ...rest
    }) => {
      let completionText = '';
      if (category === 'referral') {
        const { threshold, current } = progress?.criteria[0];
        completionText = `${current}/${threshold} Referrals`;
      } else {
        const videos = progress?.criteria?.length;
        const watchedVideos = progress?.criteria?.reduce(
          (agr, { is_completed }) => {
            if (is_completed) {
              return agr + 1;
            }
            return agr;
          },
          0
        );
        completionText = `${watchedVideos}/${videos}`;
      }

      if (title === 'Watch and Earn!') {
        completionText = `${completionText} Videos`;
      } else if (title === '50% of your next episode') {
        completionText = `${completionText} Episodes`;
      }

      let daysLeft = 0;
      if (ends_at) {
        daysLeft = Math.round(
          (new Date(ends_at).getTime() - new Date().getTime()) /
            (1000 * 3600 * 24)
        );
      }

      const videoUrls = rest?.cf_video_urls;

      return {
        title,
        details: description,
        image: image.medium,
        points: points?.toLocaleString(),
        progress: progress.overall,
        completionText,
        daysLeft,
        videoUrls,
        category,
        quizId: rest?.cf_flash_quizz_id,
        quizIds: rest?.cf_quizz_ids ?? [],
        url: rest?.url ?? '',
      };
    }
  );
};

const Slider = ({ user }) => {
  const slideRef = useRef(null);
  const { width } = useViewport();
  const {
    data: challenges,
    refetch,
    isLoading,
  } = rewardsQueries.useChallenges(user?.a_id ?? '');
  const slidesData = mapSlides(challenges ?? []);
  const slidesToShow = renderSlides(isLoading ? [] : slidesData, width);

  return (
    <Box p={[2, 1, 2, 1]} position="relative" pb={[10, 10, 10, 20]}>
      <Box
        position="absolute"
        left={0}
        bottom={0}
        right={0}
        height="80%"
        bgColor="brand.red"
      />
      {!isLoading && (
        <Slide
          slidesToScroll={1}
          slidesToShow={slidesToShow}
          responsive={[
            {
              breakpoint: 1720,
              settings: {
                slidesToShow: slidesToShow,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 1224,
              settings: {
                slidesToShow: slidesToShow,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 800,
              settings: {
                slidesToShow: slidesToShow,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 600,
              settings: {
                slidesToShow: slidesToShow,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 480,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 0,
              settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
              },
            },
          ]}
          ref={slideRef}
          {...properties}
          infinite={false}
          autoplay={false}
        >
          {slidesData.map((props, i) => {
            return (
              <Center key={i}>
                <SliderItem
                  {...props}
                  refetch={refetch}
                  isLoading={isLoading}
                />
              </Center>
            );
          })}
        </Slide>
      )}
      {slidesData?.length > slidesToShow && (
        <Flex direction="row" mt={10} p={4} justify="center">
          <Button
            size="sm"
            px={2}
            onClick={() => slideRef.current.goBack()}
          >
            <Image
              _hover={{ backgroundColor: '#b92220' }}
              boxSize="40px"
              src={whiteLeftArrow}
            />
          </Button>
          <Button
            size="sm"
            px={2}
            onClick={() => slideRef.current.goNext()}
          >
            <Image
              _hover={{ backgroundColor: '#b92220' }}
              boxSize="40px"
              src={whiteRightArrow}
            />
          </Button>
        </Flex>
      )}
    </Box>
  );
};

export default Slider;
