import { Box, Text, VStack } from '@chakra-ui/react';

const SignUpGetStarted = ({ titleWeight, subtitleWeight, width, isMobile }) => {
    return (
        <VStack w={[width, width, width, '65%']} p={[2, 2, 2, 5]}>
            <Box>
                <Text
                    fontWeight={titleWeight}
                    fontSize={['xx-large', 'xx-large', 'xx-large', 'xxx-large']}
                    textAlign={'center'}
                    color="white"
                >
                    Let's Get {!isMobile && <br />} Started
                </Text>
                <Text
                    fontWeight={subtitleWeight}
                    fontSize={['large', 'large', 'large', 'larger']}
                    color="white"
                    textAlign={['center', 'center', 'center', 'center']}
                    mt={[5, 5, 5, 0]}
                >
                    Become a member for
                    <br />
                    special offers and updates!
                </Text>
            </Box>
        </VStack>
    );
};

export default SignUpGetStarted;
