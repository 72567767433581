export const CUSTOMERS_URL = process.env.REACT_APP_CUSTOMERS_API_URL;
export const REWARDS_URL = process.env.REACT_APP_REWARDS_API_URL;

export const LOCATIONS = {
  California: [
    'Concord',
    'Encinitas',
    'Oxnard',
    'San Diego',
    'San Mateo',
    'Sacramento',
    'Temecula',
  ],
  Maryland: ['Gaithersburg'],
  Massachussets: ['Foxboro'],
  Nevada: ['Las Vegas'],
  Oklahoma: ['OKC Chisholm Creek', 'OKC Penn Square'],
  Texas: ['El Paso', 'Fort Worth', 'Plano', 'Southlake'],
  Utah: ['Riverton'],
  Virginia: ['Richmond'],
};

//INFO: We use this constant to define the API_KEY for the useQuery hooks
//If you need to invalidate any query please use this constant
export const API_KEY = {
  badges: 'customer/:id/all/badges',
  claimed: 'customer/:id/claimed',
  customer: 'customer/:id',
  challenges: 'customer/:id/challenges',
  rewards: 'customer/:id/rewards',
  activities: 'customer/:id/activities',
  coupons: 'customer/:id/coupons',
  emailVerification: 'customer/validate/:code',
  quiz: 'customer/:id/quiz_questions',
};

export const START_QUIZ =
  "Welcome to the quiz! 🎉 We're excited to have you here. Put your knowledge to the test and see how well you do. Remember, the first 100 people to finish will receive a special reward. Good luck and have fun!";

export const END_QUIZ =
  'Thank you for participating! You have completed this challenge. If you are in the first 100, you will see the points reflected in your activity history along with receiving your points.';

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  CUSTOMERS_URL,
  REWARDS_URL,
  LOCATIONS,
  START_QUIZ,
  END_QUIZ,
};
