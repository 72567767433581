import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';
import {
    Box,
    Grid,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
} from '@chakra-ui/react';
import React, { useState } from 'react';
import { useViewport } from '../utils';
import { VideoPlayer } from './VideoPlayer';

export const VideoModal = ({ isOpen, onClose, urls, refetch }) => {
    const [index, setIndex] = useState(0);
    const { isMobile, isLandscape, isPortrait } = useViewport();
    const videoHeight = isPortrait ? '30%' : '100%';

    return (
        <Modal
            size={'full'}
            key={'modalVideo'}
            isCentered
            isOpen={isOpen}
            onClose={onClose}
        >
            <ModalOverlay
                bg="blackAlpha.500"
                backdropFilter="blur(15px) hue-rotate(10deg)"
            />
            <ModalContent
                bg="transparent"
                display={'flex'}
                flexDirection={'column'}
                maxHeight={'100vh'}
                height={'100vh'}
                width={'100vw'}
            >
                <ModalHeader m={1}>
                    <ModalCloseButton color="white" />
                </ModalHeader>
                <ModalBody
                    p={1}
                    flex={1}
                    display={'flex'}
                    alignItems={'center'}
                    justifyContent={'center'}
                >
                    <Box
                        width={isPortrait ? '95%' : '90%'}
                        height={[videoHeight, videoHeight, '40%', '100%']}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                    >
                        {urls.length && (
                            <VideoPlayer url={urls[index]} refetch={refetch} />
                        )}
                    </Box>
                </ModalBody>
                <ModalFooter p={1}>
                    <Grid
                        autoFlow="column"
                        w="100%"
                        placeContent="space-between"
                    >
                        {index !== 0 ? (
                            <ChevronLeftIcon
                                onClick={() => setIndex(index - 1)}
                                fontSize="2.5em"
                                color="white"
                            />
                        ) : (
                            <ChevronLeftIcon
                                fontSize="2.5em"
                                color="grey.200"
                            />
                        )}
                        {!!urls[index + 1] ? (
                            <ChevronRightIcon
                                onClick={() => setIndex(index + 1)}
                                fontSize="2.5em"
                                color="white"
                            />
                        ) : (
                            <ChevronRightIcon
                                fontSize="2.5em"
                                color="grey.200"
                            />
                        )}
                    </Grid>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};
