import { Flex, Heading, Spacer, Text } from '@chakra-ui/react';
import { gsap } from 'gsap';
import React, { useEffect, useRef } from 'react';
import { useViewport } from '../utils';

export const SectionTitle = ({
    title = '',
    mobileTitle = '',
    description = '',
    mobileDescription = '',
    id = '',
}) => {
    const { isMobile } = useViewport();

    const headingRef = useRef(null);
    const textRef = useRef(null);

    useEffect(() => {
        const animateSection = (entries) => {
            entries.forEach((entry) => {
                if (entry.isIntersecting) {
                    const tl = gsap.timeline({
                        defaults: { ease: 'power1.out', duration: 1.8 },
                    });

                    tl.fromTo(
                        headingRef.current,
                        { opacity: 0, y: -20 },
                        { opacity: 1, y: 0 }
                    ).fromTo(
                        textRef.current,
                        { opacity: 0, y: 20 },
                        { opacity: 1, y: 0 },
                        '-=0.8'
                    );

                    observer.unobserve(entry.target); // To animate only once
                }
            });
        };

        const observer = new IntersectionObserver(animateSection, {
            threshold: 0.1, // Triggers when 10% of the element is visible
        });

        if (headingRef.current && textRef.current) {
            observer.observe(headingRef.current);
            observer.observe(textRef.current);
        }

        return () => {
            observer.disconnect();
        };
    }, []);

    return (
        <Flex
            w="100%"
            h={['200px', '200px', '250px', '250px']}
            px={[2, 2, 2, 10]}
            py={[5, 5, 5, 10]}
            id={id}
        >
            <Flex
                w="100%"
                direction={['column', 'column', 'column', 'row']}
                align={['center', 'center', 'center', 'center']}
                paddingBottom={['20px', '20px', '20px', '20px']}
                borderBottom="1px solid #cbd0d1"
            >
                <Heading
                    ref={headingRef} // Attach ref to the heading
                    as="h1"
                    color="brand.green"
                    w={['auto', 'auto', 'auto', '40%']}
                    fontSize={['x-large', 'x-large', 'x-large', 'xxx-large']}
                >
                    {isMobile ? mobileTitle : title}
                </Heading>
                {!isMobile && <Spacer w={['10%', 'auto', 'auto', '30%']} />}
                <Text
                    ref={textRef} // Attach ref to the text
                    color="brand.green"
                    w={['auto', 'auto', 'auto', '40%']}
                    fontSize="x-large"
                    fontWeight={400}
                    textAlign={['center', 'center', 'center', 'left']}
                >
                    {isMobile ? mobileDescription : description}
                </Text>
            </Flex>
        </Flex>
    );
};

export default SectionTitle;
