import { getIdToken, onAuthStateChanged } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { createContext, useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { auth, db } from '../firebase'; // Assuming you have a Firebase instance
import customerQueries from '../hooks/queries/customer';
import { setAuthToken } from '../utils/helpers/auth';

const contextDefaultValue = {
    user: {
        //
    },
    coupons: {
        //
    },
    setUser: () => false,
    refreshActivityHistory: () => false,
};

export const UserContext = createContext(contextDefaultValue);

const findReferralCode = (codes) =>
    codes?.find(({ code }) => code?.match(/^ref_.*/)?.length);
const getUserData = (uid) => doc(db, 'users', uid);
const setUserData = async (user, cb, fCoupons) => {
    const customData = await getDoc(getUserData(user?.uid));
    const antavo_id = customData?.get('aid');
    const coupons = await fCoupons(antavo_id);
    const referralCode = findReferralCode(coupons?.data?.data);
    cb({
        ...user,
        isAuthenticated: true,
        a_id: antavo_id ?? false,
        referralCode: referralCode?.code,
    });
};
const refreshActivityHistory = async (user, cb, fActivities) => {
    const { data } = await fActivities({ userId: user?.a_id ?? '' });
    cb({
        history: data,
        ...user,
    });
};

export const UserContextWrapper = ({ children }) => {
    const [user, setUser] = useState();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const [searchParams] = useSearchParams();
    const verificationCode = searchParams.get('code');

    const { fetchCoupons } = customerQueries.useFetchCoupons();
    const { mutateAsync: fetchActivityHistory } =
        customerQueries.useGetActivityHistory();
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (!user) {
                if (pathname !== '/signup' && !verificationCode) {
                    navigate('/login');
                }
            } else {
                if (user.uid) {
                    // Auth Token from Firebase
                    const token = await getIdToken(user, true);
                    // Set Auth Token for API requests on a Singleton
                    setAuthToken(token);
                    const customData = await getDoc(getUserData(user?.uid));
                    const antavo_id = customData?.get('aid');
                    const coupons = await fetchCoupons(antavo_id);
                    const referralCode = coupons?.data?.[0]?.code ?? null;

                    const { data } = await fetchActivityHistory({
                        userId: antavo_id ?? '',
                    });

                    setUser({
                        ...user,
                        isAuthenticated: true,
                        a_id: antavo_id ?? false,
                        referralCode: referralCode,
                        history: data,
                        token,
                    });
                }
            }
        });

        return () => unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [auth, navigate, pathname]);

    return (
        <UserContext.Provider
            value={{
                user,
                setUser: (user) => setUserData(user, setUser, fetchCoupons),
                refreshActivityHistory: () =>
                    refreshActivityHistory(user, setUser, fetchActivityHistory),
            }}
        >
            {children}
        </UserContext.Provider>
    );
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
    UserContext,
    UserContextWrapper,
};
