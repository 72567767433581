import { Box, Center, Flex, HStack, Text, VStack } from '@chakra-ui/react';

//INFO: in case number is positive, add a plus sign
const formatNumber = (number) => {
    return number > 0 ? `+${number}` : number;
};

const ActivityList = ({ currentItems }) => (
    <VStack gap={24} my={4} p={4} w="100%">
        {currentItems?.map((item, index) => (
            <Flex gap={2} w="100%" key={index}>
                <VStack
                    gap={2}
                    borderLeftColor={item.color}
                    borderLeftWidth={4}
                    px={2}
                    align="start"
                >
                    <Text
                        color="brand.green"
                        fontSize={['large', 'xx-large']}
                        fontWeight={700}
                    >
                        {item.title}
                    </Text>
                    <Text color="#3333334D" fontSize={['large', 'larger']}>
                        {item.date.replace(',', '')}
                    </Text>
                </VStack>
                <Center mx={2} flex={1}>
                    <Box w="100%" height="1.5px" backgroundColor={item.color} />
                </Center>
                <HStack gap={1}>
                    <Text
                        fontWeight={700}
                        fontSize={['x-large', 'xx-large']}
                        color={item.color}
                    >
                        {formatNumber(item.points)}
                    </Text>
                    <Text color={item.color} fontSize={['large', 'larger']}>
                        points
                    </Text>
                </HStack>
            </Flex>
        ))}
    </VStack>
);

export default ActivityList;
