import { useMutation, useQuery } from '@tanstack/react-query';
import { API_KEY, REWARDS_URL } from '../../utils';

import { getApiInstance } from '../../utils/helpers/api';

const rewardsServiceUrl = process.env.REACT_APP_REWARDS_API_URL ?? REWARDS_URL;

const api = getApiInstance(rewardsServiceUrl);

const useCustomerBadges = (userId, option) =>
    useQuery({
        queryKey: [API_KEY.badges, userId],
        queryFn: async () => {
            const data = await api
                .get(`customer/${userId}/all/badges`);

            return data?.data;
        },
        enabled: !!userId,
        ...option,
    });

const useRewards = (userId, option) =>
    useQuery({
        queryKey: [API_KEY.rewards, userId],
        queryFn: async () => {
            const data = api
                .get(`customer/${userId}/rewards`)
                .then((res) => res.data);

            return data;
        },
        enabled: !!userId,
        ...option,
    });

const useChallenges = (userId, option) =>
    useQuery({
        queryKey: [API_KEY.challenges, userId],
        queryFn: async () => {
            const data = api
                .get(`customer/${userId}/challenges`)
                .then((res) => res.data);

            return data;
        },
        enabled: !!userId,
        ...option,
    });

const useClaimReward = (options) =>
    useMutation({
        mutationFn: async ({ rewardId, customerId }) =>
            await api.post(`rewards/${rewardId}/claim`, { customerId }),
        ...options,
    });

const rewardsQueries = {
    useCustomerBadges,
    useRewards,
    useChallenges,
    useClaimReward,
};

export default rewardsQueries;
