import React, { useRef } from 'react';
import { Flex, Text } from '@chakra-ui/react';
import { useViewport } from '../../../utils';

const TierBenefit = ({ benefits, title, icon, points }) => {
    const { isMobile } = useViewport();
    const ref = useRef(null); // Create a ref for the animation target

    // useEffect(() => {
    //     const tl = gsap.timeline({ defaults: { duration: 0.5 } });

    //     tl.from(ref.current, { opacity: 0, scale: 0.8, y: 20, stagger: 0.1 });

    //     return () => {
    //         // Optionally kill the animation on unmount
    //         // tl.kill();
    //     };
    // }, []);
    return (
        <Flex
            ref={ref}
            width={isMobile ? '100vw' : 'auto'} // Full screen width on mobile, fixed width on desktop
            height={isMobile ? 'fit-content' : 'fit-content'} // Full height on mobile, auto on desktop
            flexDirection="column"
            alignItems="center"
            minW="200px"
            maxW="400px"
            justifyContent="flex-start" // Center content on mobile
            scrollSnapAlign={isMobile ? 'start' : 'none'} // Snap only on mobile
            bgGradient={[
                'linear(to-b, rgb(0, 54, 68), rgb(0, 0, 0))',
                'linear(to-b, rgb(0, 54, 68), rgb(0, 0, 0))',
                'linear(to-b, rgba(0, 0, 0, 0.8), rgba(0, 54, 68, 1))',
            ]}
            pb={8}
            px={[4, 8]} // Add padding
        >
            <Text
                fontSize={['3xl', '4xl']} // Adjust the size as needed
                fontWeight="bold" // Makes the text bold
                color="white" // White text color
                mt={8}
                textAlign="center"
            >
                {title}
            </Text>
            <img src={icon} alt="benefit" />
            <Text
                fontSize={['xl', '2xl']} // Adjust the size as needed
                fontWeight="bold" // Makes the text bold
                color="white" // White text color
                my={4}
            >
                {points?.toLocaleString()}
            </Text>
            {benefits.map((benefit, index) => (
                <Text
                    maxW="100%"
                    key={index}
                    color="white"
                    fontSize={['lg', 'xl']} // Adjust the size as needed
                    textAlign="center"
                    fontWeight="bold"
                    my={3}
                    wordBreak="break-word" // Ensure words break to the next line if too long
                    overflowWrap="break-word" // Ensure wrapped text
                    whiteSpace="normal" // Allow wrapping of text onto the next line
                    width="inherit"
                    px={5} // Add padding on the x-axis
                >
                    {benefit}
                </Text>
            ))}
        </Flex>
    );
};

export default TierBenefit;
