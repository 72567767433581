import {
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  SimpleGrid,
  Text,
  useBreakpointValue,
} from '@chakra-ui/react';

import { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import MinimalisticCoupon from '../Coupons/MinimalisticCoupon';

const AllCouponsModal = ({ isOpen, onClose, rewardsCoupons }) => {
  const itemsPerPage = useBreakpointValue({
    base: 7, // Base number
    sm: 7, // Mobile view
    md: 10, // Medium screens (tablets)
    lg: 12, // Larger screens (desktops)
    xl: 14, // Extra-large screens
  });
  const itemsPerMargin = useBreakpointValue({
    base: 1, // Base number
    sm: 1, // Mobile view
    md: 2, // Medium screens (tablets)
    lg: 3, // Larger screens (desktops)
    xl: 3, // Extra-large screens
  });
  const withRewardCoupons = rewardsCoupons?.map((coupon) => {
    if (coupon.name?.match(/^Birthday coupon/ig)?.length && !coupon?.reward) {
      return {
        ...coupon,
        reward: 'Birthday Coupon',
      };
    }
    return coupon;
  }).filter((coupon) => coupon.reward);

  const [itemOffset, setItemOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);

  const endOffset = itemOffset + itemsPerPage;
  const currentItems = withRewardCoupons?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(withRewardCoupons?.length / itemsPerPage);

  const handlePageClick = (event) => {
    const newOffset =
      (event.selected * itemsPerPage) % withRewardCoupons?.length;
    setItemOffset(newOffset);
    setCurrentPage(event.selected);
  };

  useEffect(() => {
    setItemOffset(0);
    setCurrentPage(0);
  }, [onClose, isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      size={['full', 'xl']}
    >
      <ModalOverlay />
      <ModalContent
        minWidth="fit-content"
        height="fit-content"
        overflow={'auto'}
      >
        <ModalCloseButton
          borderRadius="full"
          bg="brand.green"
          color="white"
          fontSize="lg"
          mt={3}
          _hover={{
            bg: 'brand.green',
            borderColor: 'red.600',
          }}
        />
        <ModalHeader>
          <Text fontWeight="bold" fontSize="2xl" color="teal.900">
            Available promo codes
          </Text>
          <Text fontSize="lg" color="gray.600">
            Redeem a reward to unlock discounts on these eligible
            items.
          </Text>
        </ModalHeader>
        <ModalBody
          minHeight={['540px', '550px', '400px', '500px', '550px']}
          maxHeight={['540px', '550px', '400px', '500px', '550px']}
        >
          <SimpleGrid
            columns={[1, 1, 2]} // 1 column for mobile, 2 for tablet/desktop
            spacing={[2, 3]} // Space between grid items
            mt={1}
          >
            {currentItems?.map((coupon) => (
              <MinimalisticCoupon
                key={coupon.code} // Always use a unique key in map
                code={coupon.code}
                expirationDate={coupon.expirationDate}
                reward={coupon.reward}
              />
            ))}
          </SimpleGrid>
        </ModalBody>
        <ModalFooter justifyContent={'center'}>
          {pageCount > 1 && (
            <Flex
              h="80px"
              w="85%"
              minWidth="85%"
              maxWidth="85%"
              justifyContent="center"
              align={'center'}
              border="1px solid"
              borderColor="gray.100"
              mt={4}
            >
              <ReactPaginate
                breakLabel="..."
                nextLabel={
                  <Button
                    size={['sm', 'sm', 'sm', 'md']}
                    variant="greenBorder"
                    isDisabled={
                      currentPage === pageCount - 1
                    }
                    _disabled={{
                      opacity: 0.5,
                      cursor: 'not-allowed',
                    }}
                  >
                    Next
                  </Button>
                }
                onPageChange={handlePageClick}
                pageRangeDisplayed={1}
                marginPagesDisplayed={itemsPerMargin}
                pageCount={pageCount}
                previousLabel={
                  <Button
                    size={['sm', 'sm', 'sm', 'md']}
                    variant="greenBorder"
                    isDisabled={currentPage === 0}
                    _disabled={{
                      opacity: 0.5,
                      cursor: 'not-allowed',
                    }}
                  >
                    Previous
                  </Button>
                }
                renderOnZeroPageCount={null}
                className="react-paginate"
                activeLinkClassName="active-link"
              />
            </Flex>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default AllCouponsModal;
