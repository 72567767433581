import { FormLabel } from '@chakra-ui/react';

const FloatingLabel = ({ children }) => {
    return (
        <FormLabel
            position="absolute"
            top={'10%'}
            left="10px"
            fontSize={['x-small', 'x-small', 'x-small', 'small']}
            transform={'translateY(0)'}
            backgroundColor="white" // to make sure it's visible
            pointerEvents="none" // avoid clicking on the label
            transition="all 0.2s ease-in-out"
            color={'brand.lightGray'}
        >
            {children}
        </FormLabel>
    );
};

export default FloatingLabel;
