import { ChakraProvider } from '@chakra-ui/react';
import React from 'react';
import ProfileManagement from './ProfileManagement';
import TermsAndConditions from './TermsAndConditions/TermsAndConditions';
import redDoorTheme from './theme';

import {
    MutationCache,
    QueryCache,
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { createHashRouter, RouterProvider } from 'react-router-dom';
import { UserContextWrapper } from './UserContext/UserContext';

// Components to render pages
import Benefits from './pages/benefits/Benefits';
import { ConfirmPassword } from './pages/ConfirmPassword/ConfirmPassword';
import { EmailVerification } from './pages/EmailVerification/EmailVerification';
import Home from './pages/home/Home';
import Login from './pages/login/Login';
import { ResetPassword } from './pages/ResetPassword/ResetPassword';
import SignUp from './pages/signup/SignUp';

const router = createHashRouter([
    {
        path: '/',
        element: (
            <UserContextWrapper>
                <Home />
            </UserContextWrapper>
        ),
    },
    {
        path: '/login',
        element: (
            <UserContextWrapper>
                <Login />
            </UserContextWrapper>
        ),
    },
    {
        path: '/signup',
        element: (
            <UserContextWrapper>
                <SignUp />
            </UserContextWrapper>
        ),
    },
    {
        path: '/terms',
        element: <TermsAndConditions />,
    },
    {
        path: '/profile',
        element: (
            <UserContextWrapper>
                <ProfileManagement />
            </UserContextWrapper>
        ),
    },
    {
        path: '/benefits',
        element: (
            <UserContextWrapper>
                <Benefits />
            </UserContextWrapper>
        ),
    },
    {
        path: '/reset/password',
        element: <ResetPassword />,
    },
    {
        path: '/confirm/password',
        element: <ConfirmPassword />,
    },
    {
        path: '/email/verification',
        element: <EmailVerification />,
    },
]);

const App = () => {
    const [queryClient] = React.useState(
        () =>
            new QueryClient({
                defaultOptions: {
                    queries: {
                        refetchOnWindowFocus: false,
                    },
                },
                queryCache: new QueryCache({
                    //INFO: Global fetch error toast message handling
                    onError: (error, query) => {
                        if (!query?.meta?.disableGlobalErrorNotification) {
                            // window.alert(`${error} API Error`);
                        }
                    },
                }),
                mutationCache: new MutationCache({
                    onError: (error, context, variables, mutation) => {
                        if (!mutation?.meta?.disableGlobalErrorNotification) {
                            window.alert(`${error} API Error`);
                        }
                    },
                }),
            })
    );

    return (
        <React.StrictMode>
            <ChakraProvider theme={redDoorTheme}>
                <QueryClientProvider client={queryClient}>
                    <RouterProvider router={router} />
                    <ReactQueryDevtools initialIsOpen={false} />
                </QueryClientProvider>
            </ChakraProvider>
        </React.StrictMode>
    );
};

export default App;
