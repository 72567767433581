import {
    Avatar,
    Box,
    Button,
    Center,
    Flex,
    FocusLock,
    HStack,
    Image,
    Link,
    Popover,
    PopoverBody,
    PopoverContent,
    PopoverTrigger,
    Portal,
    Spacer,
    Text,
    VStack,
} from '@chakra-ui/react';
import { useQueryClient } from '@tanstack/react-query';
import { signOut } from 'firebase/auth';
import { gsap } from 'gsap';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from './firebase';
import customerQueries from './hooks/queries/customer';
import logo from './images/Logo.svg';
import { useViewport } from './utils';
import { clearAuthToken } from './utils/helpers/auth';
import { scrollToSection } from './utils/helpers/scrollToSection';
import { useHash } from './utils/helpers/useHash';

function Header({ user }) {
    const { isMobile } = useViewport();
    const [isPopoverOpen, setIsPopoverOpen] = useState(false);
    const { data: fetchedUser } = customerQueries.useCustomer(user?.a_id ?? '');

    const queryClient = useQueryClient();

    const popoverContentRef = useRef(null);
    const navigate = useNavigate();
    const hash = useHash();

    //animate the popover whenever it opens
    useEffect(() => {
        if (isPopoverOpen && popoverContentRef.current) {
            gsap.fromTo(
                popoverContentRef.current,
                { scale: 0, opacity: 0, transformOrigin: 'top center' }, // Start from 0 scale
                { scale: 1, opacity: 1, duration: 0.3, ease: 'power2.out' } // Animate to full size
            );
        }
    }, [isPopoverOpen]);

    //redirect user to desired section
    useEffect(() => {
        const section = hash.replace('#', '');
        if (section) scrollToSection(section);
    }, [hash]);

    return (
        <>
            <Flex
                direction="row"
                bgColor="white"
                alignItems="center"
                px={[8, 8]}
                zIndex="sticky"
                position="sticky"
                top="0" // Keeps the header at the top
                boxShadow="md" // O
            >
                <Link href="#" _disabled={true} pl={[0, 3]} cursor={'default'}>
                    <Image w={[117, 223]} src={logo} alt="logo" />
                </Link>
                {!isMobile && (
                    <>
                        <Spacer />
                        <Center>
                            <Flex direction="row">
                                <Link
                                    href={
                                        hash.includes('benefits')
                                            ? '#/?section=rewards-section'
                                            : '#/'
                                    }
                                    onClick={() =>
                                        scrollToSection('rewards-section')
                                    }
                                >
                                    <Text
                                        color="brand.green"
                                        fontSize="large"
                                        fontWeight="700"
                                        mr={5}
                                    >
                                        REWARDS
                                    </Text>
                                </Link>
                                <Link
                                    href="https://store.reddoorescape.com/"
                                    target="_blank"
                                >

                                    <Text
                                        color="brand.green"
                                        fontSize="large"
                                        fontWeight="700"
                                        mr={5}
                                    >
                                        STORE
                                    </Text>
                                </Link>
                                <Link
                                    href="#/benefits"
                                    onClick={() => window.scrollTo(0, 0)}
                                >
                                    <Text
                                        color="brand.green"
                                        fontSize="large"
                                        fontWeight="700"
                                        mr={5}
                                    >
                                        TIER BENEFITS
                                    </Text>
                                </Link>
                            </Flex>
                        </Center>
                    </>
                )}
                <Spacer />
                <Popover
                    offset={[0, isMobile ? 5 : 22]}
                    isOpen={isPopoverOpen}
                    onOpen={() => {
                        popoverContentRef.current.focus();
                    }}
                    onClose={() => setIsPopoverOpen(false)}
                >
                    <PopoverTrigger>
                        <HStack
                            cursor={'pointer'}
                            mr={3}
                            onClick={() => setIsPopoverOpen(!isPopoverOpen)}
                        >
                            <Avatar boxSize={['35px', '58px']} />
                            <Box>
                                <Text
                                    textAlign="left"
                                    color="brand.green"
                                    fontSize="small"
                                    fontWeight="700"
                                >
                                    {fetchedUser?.first_name ?? 'user-name'}
                                </Text>
                                {!isMobile && (
                                    <Text
                                        color="brand.green"
                                        fontSize="small"
                                        fontWeight="700"
                                    >
                                        {fetchedUser?.email ?? 'email'}
                                    </Text>
                                )}
                            </Box>
                        </HStack>
                    </PopoverTrigger>
                    <FocusLock persistentFocus={true}>
                        <PopoverContent
                            borderTop={'none'}
                            borderTopRadius={0}
                            ref={popoverContentRef}
                            w={`${isMobile ? '100vw' : 'xs'}`}
                            boxShadow={'lg'}
                            zIndex="popover"
                            transformOrigin="top"
                        >
                            <PopoverBody>
                                <VStack alignItems="stretch">
                                    {isMobile && (
                                        <>
                                            <Button
                                                variant="ghost"
                                                onClick={() => {
                                                    window.scrollTo(0, 0);
                                                    navigate('/');
                                                }}
                                            >
                                                Rewards
                                            </Button>
                                            <Link
                                                textAlign="center"
                                                href="https://store.reddoorescape.com/"
                                                target="_blank"
                                            >
                                                <Button variant="ghost">
                                                    Store
                                                </Button>
                                            </Link>
                                            <Button
                                                variant="ghost"
                                                onClick={() => {
                                                    window.scrollTo(0, 0);
                                                    navigate('/benefits');
                                                }}
                                            >
                                                Tier Benefits
                                            </Button>
                                        </>
                                    )}
                                    <Button
                                        variant="ghost"
                                        onClick={() => {
                                            navigate('/profile');
                                        }}
                                    >
                                        Profile
                                    </Button>
                                    <Button
                                        variant="ghost"
                                        onClick={() => {
                                            signOut(auth).then(() => {
                                                clearAuthToken();
                                                queryClient.clear();
                                                navigate('/login');
                                            });
                                        }}
                                    >
                                        Log out
                                    </Button>
                                </VStack>
                            </PopoverBody>
                        </PopoverContent>
                    </FocusLock>
                </Popover>
            </Flex>
            {isPopoverOpen && (
                <Portal>
                    <Box
                        position="fixed"
                        top="0"
                        left="0"
                        width="100vw"
                        height="100vh"
                        bg="rgba(0, 0, 0, 0.5)" // Semi-transparent black overlay
                        zIndex="20" // Place behind the popover but above other content
                        onClick={() => setIsPopoverOpen(false)} // Close popover when clicked on overlay
                    />
                </Portal>
            )}
        </>
    );
}

export default Header;
