import { Select } from '@chakra-ui/react';
import { Controller } from 'react-hook-form';

export const HookedSelect = ({ options, withLabel = false, ...props }) => {
    return (
        <Controller
            {...props}
            render={({ field: { onChange, value, ref } }) => (
                <Select
                    mr={1}
                    borderColor="gray.200"
                    borderWidth="1px"
                    borderRadius={1}
                    w="100%"
                    align="start"
                    h={[
                        '50px',
                        `${withLabel ? '40px' : '50px'}`,
                        `${withLabel ? '40px' : '50px'}`,
                        `${withLabel ? '60px' : '50px'}`,
                    ]}
                    pt={[
                        `${withLabel ? '30px' : '0px'}`,
                        `${withLabel ? '40px' : '0px'}`,
                        `${withLabel ? '40px' : '0px'}`,
                        `${withLabel ? '40px' : '0px'}`,
                    ]}
                    pb={[
                        `${withLabel ? '30px' : '0px'}`,
                        `${withLabel ? '40px' : '0px'}`,
                        `${withLabel ? '40px' : '0px'}`,
                        `${withLabel ? '40px' : '0px'}`,
                    ]}
                    ref={ref}
                    onChange={onChange}
                    {...props}
                >
                    {Object.keys(options).map((option) => (
                        <optgroup label={option}>
                            {options[option].map((value) => (
                                <option
                                    value={value}
                                    selected={
                                        value ===
                                        (props.defaultValue
                                            ? props.defaultValue
                                            : '')
                                    }
                                >
                                    {value}
                                </option>
                            ))}
                        </optgroup>
                    ))}
                </Select>
            )}
        />
    );
};

export default {
    HookedSelect,
};
