import { ArrowRightIcon } from '@chakra-ui/icons';
import { Box, Flex, Grid, Icon, keyframes, Text } from '@chakra-ui/react';
import React, { useEffect, useRef, useState } from 'react';
import benefit from '../../images/benefit.svg';
import welcomeBannerBg from '../../images/welcome-banner-bg.jpg';
import { useViewport } from '../../utils';
import TierBenefit from './TierBenefit/TierBenefit';

const memberBenefits = ['Play for free on your birthday'];

const escapeArtistBenefits = [
    'Play for free on your birthday',
    'Free merch: Choose from T-Shirt, Trucker Cap or Accent Mug',
    '5 single use codes at 15% which can be used on weekdays only',
    'Early access to new room launches or special events',
    '1.5x points earned when referring friends',
    'Complimentary water for the group',
    'Tier specific badge',
];

const timeMasterBenefits = [
    'Play for free on your birthday',
    'Free merch: Choose from Sweatshirts, Puzzle 520 pc or Tumbler/Travel Mug/Water Bottle',
    '10 single use codes at 20% off booking which can be used on weekdays only',
    'Exclusive paid access to in-store events (VIP parties, tournaments)',
    '1.5x points earned on all challenges',
    'Complimentary water for the group and snack',
    'Tier specific badge',
    'Flexible Booking Changes: Free cancellation protection',
    'Video highlight - Complimentary video playthrough of the highlights of your experience',
];

const architectBenefits = [
    'Play for free on your birthday',
    'Free merch: Choose from Puffer Jacket, Duffle Bag or Framed Vertical Poster',
    '10 single use codes at 20% off booking which can be used any day of the week',
    'Exclusive paid access to in-store events (VIP parties, tournaments)',
    '2x points earned on all challenges',
    'Complimentary water for the group and snack',
    'Tier specific badge',
    'Flexible Booking Changes: Free cancellation protection',
    'Video highlight - Complimentary video playthrough of the highlights of your experience',
    'Complimentary booking for your entire group (Up to max # the room can hold). Once a year while in the Tier',
    'Partner Benefits - Discounts or free offers at partnered local restaurants, cafes, or entertainment spots',
];

const benefits = [
    {
        title: 'Member',
        icon: benefit,
        benefits: memberBenefits,
        points: '0 points',
    },
    {
        title: 'Escape Artist',
        icon: benefit,
        benefits: escapeArtistBenefits,
        points: '25,000 points',
    },
    {
        title: 'Time Master',
        icon: benefit,
        benefits: timeMasterBenefits,
        points: '50,000 points',
    },
    {
        title: 'The Architect',
        icon: benefit,
        benefits: architectBenefits,
        points: '100,000 or more points',
    },
];

const pulseAnimation = keyframes`
  0% { transform: translateX(0); opacity: 1; }
  50% { transform: translateX(15px); opacity: 0.8; }
  100% { transform: translateX(0); opacity: 1; }
`;

const fadeInAnimation = keyframes`
  0% { opacity: 0; }
  100% { opacity: 1; }
`;

const TierBenefits = ({ user }) => {
    const { isMobile } = useViewport();
    const [showScrollHint, setShowScrollHint] = useState(true);
    const containerRef = useRef(null);

    useEffect(() => {
        if (isMobile) {
            const handleScroll = () => setShowScrollHint(false);
            window.addEventListener('scroll', handleScroll);

            const timer = setTimeout(() => setShowScrollHint(false), 5000);

            return () => {
                window.removeEventListener('scroll', handleScroll);
                clearTimeout(timer);
            };
        }
    }, [isMobile]);

    const scrollToNextBenefit = () => {
        if (containerRef.current) {
            const currentScrollLeft = containerRef.current.scrollLeft;
            const containerWidth = containerRef.current.offsetWidth;
            const nextScrollLeft = currentScrollLeft + containerWidth;
            containerRef.current.scrollTo({
                left: nextScrollLeft,
                behavior: 'smooth',
            });
        }
    };

    return (
        <Flex
            backgroundColor="white"
            backgroundImage={welcomeBannerBg}
            backgroundRepeat="no-repeat"
            backgroundSize="cover"
            backgroundPosition="center"
            flexDirection="column"
            pb={8}
        >
            <Text
                fontSize={['3xl', '5xl']} // Adjust the size as needed
                fontWeight="bold" // Makes the text bold
                color="white" // White text color
                my={8}
                mx={24}
            >
                Tier Benefits
            </Text>
            <Grid
                ref={containerRef}
                id="benefits-background"
                templateColumns="repeat(4, 1fr)"
                justify={isMobile ? 'flex-start' : 'center'}
                align="flex-start"
                height={isMobile ? '100vh' : 'auto'}
                width="100%"
                gap={8}
                overflowX={isMobile ? 'scroll' : 'auto'}
                scrollSnapType={isMobile ? 'x mandatory' : 'none'}
                whiteSpace={isMobile ? 'nowrap' : 'normal'}
                sx={
                    isMobile
                        ? { '&::-webkit-scrollbar': { display: 'none' } }
                        : {}
                }
                position="relative"
            >
                {benefits.map(({ benefits, title, icon, points }, index) => (
                    <TierBenefit
                        benefits={benefits}
                        title={title}
                        icon={icon}
                        id={`tier-${index}`}
                        points={points}
                        key={index}
                    />
                ))}
                {/* Scroll hint (Swipe animation) */}
                {isMobile && showScrollHint && (
                    <Box
                        position="absolute"
                        bottom="25%"
                        left="35%"
                        transform="translateX(-35%)"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        p={3}
                        bg="rgba(0, 0, 0, 0.6)"
                        borderRadius="full"
                        boxShadow="0px 4px 10px rgba(0, 0, 0, 0.3)"
                        _hover={{
                            boxShadow: '0px 0px 15px rgba(255, 255, 255, 0.8)',
                        }}
                        animation={`${fadeInAnimation} 0.5s ease-out, ${pulseAnimation} 1.5s ease-in-out infinite`}
                        onClick={scrollToNextBenefit}
                    >
                        <Text
                            fontWeight="bold"
                            fontSize="md"
                            color="white"
                            mr={2}
                            textShadow="0px 0px 5px rgba(0, 0, 0, 0.7)"
                        >
                            Swipe
                        </Text>
                        <Icon
                            as={ArrowRightIcon}
                            color="white"
                            boxSize={5}
                            ml={1}
                            filter="drop-shadow(2px 4px 6px rgba(0, 0, 0, 0.5))"
                            _hover={{
                                transform: 'scale(1.1)',
                            }}
                        />
                    </Box>
                )}
            </Grid>
        </Flex>
    );
};

export default TierBenefits;
