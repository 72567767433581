import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    Center,
    Checkbox,
    Container,
    Flex,
    FormControl,
    FormErrorMessage,
    Image,
    Input,
    InputGroup,
    InputRightElement,
    Link,
    Spacer,
    Text,
    useToast,
    VStack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useQueryClient } from '@tanstack/react-query';
import { getIdToken, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as yup from 'yup';
import HowDoesItWork from '../../components/HowDoesItWork/HowDoesItWork';
import { auth, db } from '../../firebase';
import customerQueries from '../../hooks/queries/customer';
import rewardsQueries from '../../hooks/queries/rewards';
import bg2 from '../../images/bg2.jpg';
import WhiteLogo from '../../images/whiteLogo.svg';
import { UserContext } from '../../UserContext/UserContext';
import { API_KEY, useViewport } from '../../utils';
import { clearAuthToken, setAuthToken } from '../../utils/helpers/auth';

const loginSchema = yup.object().shape({
    password: yup.string().required('Email is a required field'),
    email: yup.string().email().required('You need to input a password'),
    staySignedIn: yup.boolean().default(false).optional(),
});

const getUserData = (uid) => doc(db, 'users', uid);

function Login() {
    const [searchParams] = useSearchParams();
    const [error, setError] = useState();
    const [show, setShow] = useState(false);
    const handleClick = () => setShow(!show);
    const { isMobile } = useViewport();
    const navigate = useNavigate();
    const { setUser, user } = useContext(UserContext);
    const toast = useToast();
    const verificationCode = searchParams.get('code');

    const queryClient = useQueryClient();
    const { mutateAsync: sendEmailVerification } =
        customerQueries.useSendEmailVerification();
    const { isError, isSuccess, isLoading } = customerQueries.useValidateEmail(
        verificationCode ?? '',
        {
            enabled: !!verificationCode,
            retry: false,
        }
    );

    const { fetchCustomer } = customerQueries.useFetchCustomer();

    const handleEmailVerification = async (userId) => {
        try {
            await await sendEmailVerification({
                userId,
            });
            toast({
                title: 'Verification email resent!',
                status: 'info',
                duration: 5000,
                isClosable: true,
            });
        } catch (error) {
            console.error('Error sending email verification', error);
            toast({
                title: 'Error',
                description:
                    'Failed to send verification email. Please try again later.',
                status: 'error',
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const applyEmailVerification = async () => {
        if (!isLoading) {
            if (isSuccess) {
                // Display a success toast
                toast({
                    title: 'Email Verified',
                    description: 'Account has been verified!',
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                });
            } else {
                // Display an error toast
                toast({
                    title: 'Verification Failed',
                    description:
                        'The email verification failed. The link might be invalid or expired.',
                    status: 'error',
                    duration: 5000,
                    isClosable: true,
                });
            }
        }
    };

    useEffect(() => {
        // Get the verification code from the query params
        if (verificationCode) {
            applyEmailVerification();
        }
    }, [verificationCode, isError, isSuccess, isLoading]);

    const {
        register,
        handleSubmit,
        setValue,
        formState: { isSubmitting, errors },
        control,
    } = useForm({
        resolver: yupResolver(loginSchema),
    });

    const onSubmit = async ({ email, password }) => {
        try {
            const noSpaceEmail = email.trim(); // Trim the email input
            const userCredential = await signInWithEmailAndPassword(
                auth,
                noSpaceEmail,
                password
            );

            if (userCredential) {
                const customData = await getDoc(
                    getUserData(userCredential.user.uid)
                );
                const antavo_id = customData?.get('aid');
                const token = await getIdToken(userCredential.user, true);
                setAuthToken(token);
                const customer = await fetchCustomer(antavo_id);

                if (customer?.properties.cf_is_email_verified) {
                    setUser(userCredential?.user);
                    await queryClient.prefetchQuery({
                        queryFn: async () =>
                            customerQueries.fetchCustomer(user?.a_id),
                        queryKey: [API_KEY.customer, user?.a_id],
                    });

                    await queryClient.prefetchQuery({
                        queryFn: async () =>
                            rewardsQueries.useChallenges(user?.a_id ?? ''),
                        queryKey: [API_KEY.challenges, user?.a_id],
                    });
                    navigate('/');
                } else {
                    toast({
                        title: 'Account not verified!',
                        description: (
                            <Box>
                                Please check your inbox for the verification
                                email or
                                <Link
                                    onClick={() =>
                                        handleEmailVerification(antavo_id)
                                    } // This will resend the email
                                    cursor="pointer"
                                    textDecoration={'underline'}
                                    mx={1}
                                >
                                    click here
                                </Link>
                                to resend the email
                            </Box>
                        ),
                        status: 'info',
                        duration: 9000,
                        isClosable: true,
                    });

                    await signOut(auth).then(() => {
                        clearAuthToken();
                        queryClient.clear();
                    }); // Sign out the user
                }
            }
        } catch (error) {
            console.log(error);
            setError('There was an error logging in.');
        }
    };

    const handleEmailChange = (e) => {
        const trimmedValue = e.target.value.trim();
        setValue('email', trimmedValue, {
            shouldValidate: true,
        });
    };

    return (
        <Container w="100%" h="100%" maxW="initial" p={0} mb={20}>
            <Container
                w="100vw"
                h="100vh"
                maxW="initial"
                py={[5, 5, 5, 10]}
                backgroundColor="white"
                backgroundImage={bg2}
                backgroundRepeat="no-repeat"
                backgroundSize="cover"
            >
                <Center h="100%" mb={[5, 5, 5, 0]}>
                    {!isMobile ? (
                        <Image
                            w={183}
                            src={WhiteLogo}
                            position="absolute"
                            top={10}
                            right={10}
                        />
                    ) : (
                        <Image
                            w={183}
                            src={WhiteLogo}
                            position="absolute"
                            top={120}
                        />
                    )}
                    <VStack
                        w={['90%', '90%', '90%', '40%']}
                        p={[5, 5, 5, 10]}
                        backgroundColor="white"
                    >
                        <form
                            onSubmit={handleSubmit(onSubmit, (err) => {})}
                            style={{ width: '100%' }}
                        >
                            <FormControl
                                isInvalid={errors.email || errors.password}
                            >
                                <VStack alignItems="start" w="100%" gap={5}>
                                    <Box pb={[2, 2, 2, 4]}>
                                        <Text
                                            fontWeight={700}
                                            fontSize={[
                                                'x-large',
                                                'x-large',
                                                'x-large',
                                                'xxx-large',
                                            ]}
                                            color="brand.red"
                                        >
                                            Sign in
                                        </Text>
                                        <Text
                                            color="brand.green"
                                            fontSize="x-large"
                                        >
                                            Please login to continue to your
                                            account
                                        </Text>
                                    </Box>

                                    <Box w="100%">
                                        <Input
                                            borderRadius="0"
                                            name="email"
                                            placeholder="username or e-mail*"
                                            {...register('email')}
                                            onChange={handleEmailChange}
                                        />
                                        {errors.email && (
                                            <FormErrorMessage>
                                                {errors.email.message}
                                            </FormErrorMessage>
                                        )}
                                    </Box>

                                    <InputGroup size="md">
                                        <Input
                                            borderRadius="0"
                                            pr="4.5rem"
                                            type={show ? 'text' : 'password'}
                                            placeholder="password*"
                                            {...register('password')}
                                        />
                                        <InputRightElement width="4.5rem">
                                            <Button
                                                variant="ghost"
                                                h="1.75rem"
                                                size="sm"
                                                onClick={handleClick}
                                            >
                                                {show ? (
                                                    <ViewIcon />
                                                ) : (
                                                    <ViewOffIcon />
                                                )}
                                            </Button>
                                        </InputRightElement>
                                    </InputGroup>
                                    {errors.password && (
                                        <FormErrorMessage>
                                            {errors.password.message}
                                        </FormErrorMessage>
                                    )}

                                    {error?.length && (
                                        <Text color="brand.red">{error}</Text>
                                    )}

                                    <Flex direction="row" w="100%">
                                        <Controller
                                            control={control}
                                            name="staySignedIn"
                                            defaultValue={false}
                                            render={({
                                                field: { onChange, value, ref },
                                            }) => (
                                                <Checkbox
                                                    ref={ref}
                                                    width="40%"
                                                    color="brand.green"
                                                    onChange={onChange}
                                                    isChecked={value}
                                                >
                                                    <Text
                                                        fontSize={[
                                                            'small',
                                                            'small',
                                                            'small',
                                                            'medium',
                                                        ]}
                                                        color="brand.green"
                                                    >
                                                        Stay Signed In
                                                    </Text>
                                                </Checkbox>
                                            )}
                                        />
                                        <Spacer />
                                        <Link
                                            href="#/reset/password"
                                            color="brand.green"
                                            fontWeight={500}
                                            fontSize={[
                                                'small',
                                                'small',
                                                'small',
                                                'medium',
                                            ]}
                                        >
                                            Forgot password?
                                        </Link>
                                    </Flex>
                                </VStack>

                                <Button
                                    w="100%"
                                    type="submit"
                                    my={[10, 10, 0, 10]}
                                    alignSelf="stretch"
                                    size="md"
                                    textTransform="capitalize"
                                    isLoading={isSubmitting}
                                    fontSize="lg"
                                    fontWeight="semibold"
                                    letterSpacing="widest"
                                    _hover={{
                                        bg: '#f08281',
                                        transform: 'scale(1.05)',
                                        transition: 'all 0.3s ease',
                                    }}
                                    _active={{
                                        bg: '#C4201D',
                                    }}
                                >
                                    Sign in
                                </Button>
                                <VStack>
                                    <Link
                                        textAlign="center"
                                        color="brand.green"
                                        href="/#signup"
                                        fontWeight={500}
                                    >
                                        Can't sign in?
                                        <br />
                                        Create account
                                    </Link>
                                </VStack>
                            </FormControl>
                        </form>
                    </VStack>
                </Center>
            </Container>
            <HowDoesItWork />
        </Container>
    );
}

export default Login;
