import { Box, Button, Flex, Image } from '@chakra-ui/react';
import { useRef, useState } from 'react';
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';

import customerQueries from '../../hooks/queries/customer';

import BlackLeftArrow from '../../images/BlackArrowLeft.svg';
import BlackRightArrow from '../../images/BlackArrowRight.svg';
import DisabledLeftArrow from '../../images/DisabledArrowLeft.svg';
import DisabledRightArrow from '../../images/DisabledArrowRight.svg';
import { useViewport } from '../../utils';

import RewardItemWrap from './RewardItemWrap';

const buttonStyle = {
    display: 'none',
};

const properties = {
    prevArrow: <button id="leftBtn" style={{ ...buttonStyle }}></button>,
    nextArrow: <button id="rightBtn" style={{ ...buttonStyle }}></button>,
};

function RewardSlider({
    rewards,
    spendablePoints,
    user,
    refreshActivities,
    refreshCustomerData,
    refreshRewards,
}) {
    const [slideIndex, setSlideIndex] = useState(0);
    const { data: customerData } = customerQueries.useCustomer(
        user?.a_id ?? '',
        { enabled: false }
    );

    const { isMobile, width } = useViewport();
    const slideRef = useRef(null);

    // Function to determine how many slides to show based on width
    const numberOfSlides = () => {
        if (width < 1520 || isMobile) {
            return 1;
        } else if (width <= 2652) {
            return 2;
        } else {
            return 3;
        }
    };

    // Calculate the maximum index where the last slide can start on the left
    const maxIndex = rewards.length - numberOfSlides();

    const sortRewards = (rewards) => {
        const favoriteRewards =
            customerData?.properties?.favorite_rewards ?? [];

        const status = ['active', 'inactive', 'scheduled'];

        const sortedRewards = [...rewards].sort((a, b) => {
            const isAFavorite = favoriteRewards.includes(a.id);
            const isBFavorite = favoriteRewards.includes(b.id);

            if (isAFavorite && isBFavorite) {
                return parseInt(a.points, 10) - parseInt(b.points, 10);
            }

            if (isAFavorite && !isBFavorite) return -1;
            if (!isAFavorite && isBFavorite) return 1;

            if (!isAFavorite && !isBFavorite) {
                if (a.status === b.status)
                    return parseInt(a.points, 10) - parseInt(b.points, 10);
                else {
                    return status.indexOf(a.status) - status.indexOf(b.status);
                }
            }
        });

        return sortedRewards;
    };

    const handleNext = () => {
        // Check if we are at the last slide
        if (slideIndex < maxIndex) {
            slideRef.current.goNext(); // Advance to the next slide
            setSlideIndex(slideIndex + 1); // Update the state
        }
    };

    const handlePrev = () => {
        if (slideIndex > 0) {
            slideRef.current.goBack(); // Go to the previous slide
            setSlideIndex(slideIndex - 1); // Update the state
        }
    };

    return (
        <Box
            p={[2, 2, 2, 50]}
            position="relative"
            height="inherit"
            width="100%"
            id="rewards-slider"
        >
            {/* <Show above="md"> */}
            <Slide
                slidesToScroll={1}
                slidesToShow={numberOfSlides()}
                autoplay={false}
                infinite={false}
                ref={slideRef}
                {...properties}
                responsive={[
                    {
                        breakpoint: 1720,
                        settings: {
                            slidesToShow: numberOfSlides(),
                            slidesToScroll: 1,
                        },
                    },
                    {
                        breakpoint: 1224,
                        settings: {
                            slidesToShow: numberOfSlides(),
                            slidesToScroll: 1,
                        },
                    },
                    {
                        breakpoint: 800,
                        settings: {
                            slidesToShow: numberOfSlides(),
                            slidesToScroll: 1,
                        },
                    },
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: numberOfSlides(),
                            slidesToScroll: 1,
                        },
                    },
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                        },
                    },
                ]}
            >
                {/* <span>Slide 1</span> */}
                {sortRewards(rewards).map((props, i) => (
                    <RewardItemWrap
                        key={`${i}-slide`}
                        spendablePoints={spendablePoints}
                        {...props}
                        user={user}
                        refreshActivities={refreshActivities}
                        refreshCustomerData={refreshCustomerData}
                        refreshRewards={refreshRewards}
                    />
                ))}
            </Slide>
            {isMobile ? (
                // Buttons below the slider for mobile view
                <Flex justify="center" mt={4} gap={2}>
                    <Button
                        variant="ghost"
                        size="sm"
                        onClick={handlePrev}
                        _hover={{
                            background: 'gray.100',
                            cursor:
                                slideIndex === 0 ? 'not-allowed' : 'pointer',
                        }}
                        backgroundColor="transparent"
                        px={0}
                    >
                        <Image
                            boxSize="32px"
                            src={
                                slideIndex === 0
                                    ? DisabledLeftArrow
                                    : BlackLeftArrow
                            }
                        />
                    </Button>
                    <Button
                        variant="ghost"
                        size="sm"
                        onClick={handleNext}
                        backgroundColor="transparent"
                        px={0}
                        _hover={{
                            background: 'gray.100',
                            cursor:
                                slideIndex >= maxIndex
                                    ? 'not-allowed'
                                    : 'pointer',
                        }}
                    >
                        <Image
                            boxSize="32px"
                            src={
                                slideIndex >= maxIndex
                                    ? DisabledRightArrow
                                    : BlackRightArrow
                            }
                        />
                    </Button>
                </Flex>
            ) : (
                <>
                    {/* Left Button for desktop view */}
                    <Button
                        variant="ghost"
                        size="sm"
                        position="absolute"
                        top="50%"
                        left="2"
                        transform="translateY(-50%)"
                        zIndex={1}
                        onClick={handlePrev}
                        _hover={{
                            background: 'gray.100',
                            cursor:
                                slideIndex === 0 ? 'not-allowed' : 'pointer',
                        }}
                        backgroundColor="transparent"
                        px={0}
                    >
                        <Image
                            boxSize="32px"
                            src={
                                slideIndex === 0
                                    ? DisabledLeftArrow
                                    : BlackLeftArrow
                            }
                        />
                    </Button>

                    {/* Right Button for desktop view */}
                    <Button
                        variant="ghost"
                        size="sm"
                        position="absolute"
                        top="50%"
                        right="0"
                        transform="translateY(-50%)"
                        zIndex={1}
                        px={0}
                        onClick={handleNext}
                        backgroundColor="transparent"
                        _hover={{
                            background: 'gray.100',
                            cursor:
                                slideIndex >= maxIndex
                                    ? 'not-allowed'
                                    : 'pointer',
                        }}
                    >
                        <Image
                            boxSize="32px"
                            src={
                                slideIndex >= maxIndex
                                    ? DisabledRightArrow
                                    : BlackRightArrow
                            }
                        />
                    </Button>
                </>
            )}
        </Box>
    );
}

export default RewardSlider;
