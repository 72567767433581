import { useViewport } from '../utils';

export const DynamicProgressBar = ({ percentage, width }) => {
  const { isMobile } = useViewport();
  const redBarCount = percentage / 10;
  const bars = Array(10).fill("#CCC9C9").map((color, i) => (i + 1) <= redBarCount ? '#E82C2A' : color);

  if (isMobile) {
    return (
      <svg width={width} height="45" viewBox="0 0 215 45" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M0 1C0 0.447715 0.447715 0 1 0H7C7.55228 0 8 0.447715 8 1V44C8 44.5523 7.55228 45 7 45H1C0.447715 45 0 44.5523 0 44V1Z" fill={bars[0]} />
        <path d="M23 1C23 0.447715 23.4477 0 24 0H30C30.5523 0 31 0.447715 31 1V44C31 44.5523 30.5523 45 30 45H24C23.4477 45 23 44.5523 23 44V1Z" fill={bars[1]} />
        <path d="M46 1C46 0.447715 46.4477 0 47 0H53C53.5523 0 54 0.447715 54 1V44C54 44.5523 53.5523 45 53 45H47C46.4477 45 46 44.5523 46 44V1Z" fill={bars[2]} />
        <path d="M69 1C69 0.447715 69.4477 0 70 0H76C76.5523 0 77 0.447715 77 1V44C77 44.5523 76.5523 45 76 45H70C69.4477 45 69 44.5523 69 44V1Z" fill={bars[3]} />
        <path d="M92 1C92 0.447715 92.4477 0 93 0H99C99.5523 0 100 0.447715 100 1V44C100 44.5523 99.5523 45 99 45H93C92.4477 45 92 44.5523 92 44V1Z" fill={bars[4]} />
        <path d="M115 1C115 0.447715 115.448 0 116 0H122C122.552 0 123 0.447715 123 1V44C123 44.5523 122.552 45 122 45H116C115.448 45 115 44.5523 115 44V1Z" fill={bars[5]} />
        <path d="M138 1C138 0.447715 138.448 0 139 0H145C145.552 0 146 0.447715 146 1V44C146 44.5523 145.552 45 145 45H139C138.448 45 138 44.5523 138 44V1Z" fill={bars[6]} />
        <path d="M161 1C161 0.447715 161.448 0 162 0H168C168.552 0 169 0.447715 169 1V44C169 44.5523 168.552 45 168 45H162C161.448 45 161 44.5523 161 44V1Z" fill={bars[7]} />
        <path d="M184 1C184 0.447715 184.448 0 185 0H191C191.552 0 192 0.447715 192 1V44C192 44.5523 191.552 45 191 45H185C184.448 45 184 44.5523 184 44V1Z" fill={bars[8]} />
        <path d="M207 1C207 0.447715 207.448 0 208 0H214C214.552 0 215 0.447715 215 1V44C215 44.5523 214.552 45 214 45H208C207.448 45 207 44.5523 207 44V1Z" fill={bars[9]} />
      </svg>
    );
  }

  return (
    <svg width="208" height="7" viewBox="0 0 208 7" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M39 0C39.5523 0 40 0.447715 40 1V6C40 6.55228 39.5523 7 39 7L1 7C0.447716 7 0 6.55228 0 6V1C0 0.447715 0.447716 0 1 0L39 0Z" fill={bars[1]}/>
      <path d="M81 0C81.5523 0 82 0.447715 82 1V6C82 6.55228 81.5523 7 81 7L43 7C42.4477 7 42 6.55228 42 6V1C42 0.447715 42.4477 0 43 0L81 0Z" fill={bars[3]}/>
      <path d="M123 0C123.552 0 124 0.447715 124 1V6C124 6.55228 123.552 7 123 7L85 7C84.4477 7 84 6.55228 84 6V1C84 0.447715 84.4477 0 85 0L123 0Z" fill={bars[5]}/>
      <path d="M165 0C165.552 0 166 0.447715 166 1V6C166 6.55228 165.552 7 165 7L127 7C126.448 7 126 6.55228 126 6V1C126 0.447715 126.448 0 127 0L165 0Z" fill={bars[7]}/>
      <path d="M207 0C207.552 0 208 0.447715 208 1V6C208 6.55228 207.552 7 207 7L169 7C168.448 7 168 6.55228 168 6V1C168 0.447715 168.448 0 169 0L207 0Z" fill={bars[9]}/>
    </svg>
  );
};


export default {
  DynamicProgressBar,
};
