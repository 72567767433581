import {
  Button,
  Flex,
  Heading,
  HStack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import { useMemo } from 'react';
import 'react-slideshow-image/dist/styles.css';

import coin from '../../images/standing_coin_front.svg';

import { useViewport } from '../../utils';

import customerQueries from '../../hooks/queries/customer';
import rewardsQueries from '../../hooks/queries/rewards';
import RewardSlider from './RewardsSlider';

import AllCouponsModal from './RewardItem/AllCouponsModal/AllCouponsModal';

const mapRewards = (data) =>
  data.map(
    ({ title, description, points, image, id, coupon_pool, status }) => ({
      title,
      details: description,
      points: points?.toLocaleString(),
      image: image?.medium,
      couponPool: coupon_pool,
      id,
      status,
    })
  );

function Rewards({ user, refreshActivities }) {
  const { isMobile } = useViewport();

  const { isOpen, onOpen, onClose } = useDisclosure();

  const { data: customerData, refetch: refreshCustomerData } =
    customerQueries.useCustomer(user?.a_id ?? '');
  const { data: rewardsData, refetch: refreshRewards } =
    rewardsQueries.useRewards(user?.a_id ?? '');
  const { data: couponsData } = customerQueries.useCoupons(user?.a_id ?? '', {
    enabled: false,
  });

  const rewardsCoupons = useMemo(() => {
    return couponsData?.data?.map((coupon) => {
      const reward = rewardsData?.find(
        (reward) => reward.coupon_pool === coupon.pool
      )?.title;

      return {
        code: coupon.code,
        name: coupon.name,
        reward: reward,
        expirationDate: coupon.expires_at,
      };
    });
  }, [couponsData, rewardsData]);

  const spendablePoints = customerData?.spendable;

  const rewards = useMemo(() => {
    return rewardsData ? mapRewards(rewardsData ?? []) : [];
  }, [rewardsData]);

  if (!rewards?.length) {
    return <></>;
  }

  const SeeAllCodesButton = (
    <Button
      alignSelf="start"
      size="md"
      fontSize="xl"
      onClick={() => onOpen()}
      bg="brand.red"
      color="white"
      _hover={{
        bg: 'brand.red',
        transform: 'scale(1.05)',
        boxShadow: 'lg',
        opacity: 0.6,
      }}
      _active={{
        transform: 'scale(0.98)',
      }}
      _disabled={{
        bg: 'brand.lightGray',
        cursor: 'not-allowed',
        opacity: 0.6,
        boxShadow: 'none',
      }}
      isDisabled={!rewardsCoupons?.length}
    >
      {'SEE ALL CODES'}
    </Button>
  );

  return (
    <>
      <Flex
        direction={['column', 'column', 'row']} // Stack in column for mobile, row for larger screens
        padding={[0, 10]}
        mb={[10, 10, 10, 20]}
        id="rewards-grid-container"
        alignItems="center"
      >
        {/* First Section (Green Box) */}
        <Flex
          bgColor="brand.green"
          h={[180, 350]}
          w={['100%', '100%', '45%', '33%']} // 100% width for mobile, half for larger screens
          direction="column"
          padding={[5, 5, 5, 10]}
          justify="space-between"
        >
          <Flex
            direction={['row', 'row', 'column']} // Row for mobile, column for larger screens
            height={['auto', 'auto', 150, 400]}
            justify="space-between"
            alignItems="start"
            w="100%"
          >
            <Flex direction="column" gap={4}>
              <Heading
                as="h2"
                color="white"
                fontSize={[18, 18, 18, 28]}
              >
                GOOD JOB,{' '}
                {customerData?.first_name?.toUpperCase()}!
              </Heading>
              {!isMobile && SeeAllCodesButton}
            </Flex>
            <Flex
              direction="column"
              width="100%"
              justifySelf="end"
              textAlign={['right', 'left']}
            >
              <Text fontSize={[18, 18, 18, 30]} color="white">
                SPENDABLE POINTS
              </Text>
              <HStack gap={1} justify={['end', 'start']}>
                <img src={coin} alt="coin" />
                <Text fontSize={[18, 18, 18, 30]} color="white">
                  {spendablePoints?.toLocaleString()}
                </Text>
              </HStack>
            </Flex>
          </Flex>
          {isMobile && SeeAllCodesButton}
        </Flex>

        {/* Second Section (Reward Slider) */}
        <Flex w={['100%', '100%', '55%', '67%']} id="rewards-slider">
          <RewardSlider
            rewards={rewards}
            spendablePoints={spendablePoints}
            user={user}
            refreshActivities={refreshActivities}
            refreshCustomerData={refreshCustomerData}
            refreshRewards={refreshRewards}
          />
        </Flex>
      </Flex>
      <AllCouponsModal
        isOpen={isOpen}
        onClose={onClose}
        rewardsCoupons={rewardsCoupons}
      />
    </>
  );
}

export default Rewards;
