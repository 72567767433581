import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    Text,
    useDisclosure,
    Center,
    Box,
} from '@chakra-ui/react';
import information from '../../../../images/information.svg';
import informationRed from '../../../../images/information-red.svg';
import { useViewport } from '../../../../utils';

const InfoModal = ({ details }) => {
    const { isMobile } = useViewport();
    const { isOpen, onOpen, onClose } = useDisclosure();

    const onInfoIconClick = () => {
        onOpen();
    };

    return (
        <>
            <Box
                position="absolute"
                top={2}
                right={1}
                cursor="pointer"
                onClick={onInfoIconClick}
                _hover={{
                    transform: 'scale(1.1)', // Slightly enlarge the icon
                    transition: 'transform 0.2s ease-in-out', // Smooth transition effect
                }}
            >
                <img src={information} alt="see info" />
            </Box>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                isCentered
                size={isMobile ? 'full' : 'sm'}
                motionPreset="slideInBottom"
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalCloseButton
                        borderRadius="full"
                        bg="brand.green"
                        color="white"
                        fontSize="lg"
                        _hover={{
                            bg: 'brand.green',
                            borderColor: 'red.600',
                        }}
                    />
                    <ModalHeader />
                    <ModalBody>
                        <Center flexDirection="column" alignItems="center">
                            <img src={informationRed} alt="see info" />
                            <Text
                                fontSize="xl"
                                fontWeight="bold"
                                textAlign="center"
                                textColor="brand.green"
                                my={4}
                            >
                                Your Reward Details
                            </Text>
                            <Text
                                fontSize="lg"
                                fontWeight="normal"
                                textAlign="center"
                                textColor="brand.green"
                                mb={4}
                            >
                                {details}
                            </Text>
                        </Center>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </>
    );
};

export default InfoModal;
