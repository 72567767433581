import { Checkbox, Link, Text } from '@chakra-ui/react';
import { Controller } from 'react-hook-form';

export const HookedCheckbox = ({ label, ...props }) => {
  return (
    <Controller
      {...props}
      render={({ field: { onChange, value, ref } }) => (
        <Checkbox
          ref={ref}
          color="brand.green"
          onChange={onChange}
          isChecked={value}
          colorScheme="red"
        >
          {props.isLink ? (
            <Link
              href={props.link}
              textDecoration={props.textDecoration || ''}
              isExternal
            >
                {label}
            </Link>
          ) : (
            <Text
              fontSize={['small', 'small', 'small', 'medium']}
              color="brand.green"
            >
              {label}
            </Text>
          )}
        </Checkbox>
      )}
    />
  );
};
