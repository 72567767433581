import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import {
  Button,
  Center,
  Container,
  FormControl,
  FormErrorMessage,
  HStack,
  Image,
  InputGroup,
  InputRightElement,
  Link,
  Stack,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { createUserWithEmailAndPassword, signOut } from 'firebase/auth';
import { doc, setDoc } from 'firebase/firestore';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { PatternFormat } from 'react-number-format';
import { useNavigate, useSearchParams } from 'react-router-dom';
import 'yup-phone-lite';
import { HookedCheckbox } from '../../components/HookedCheckbox';
import { HookedSelect } from '../../components/HookedSelect';
import HowDoesItWork from '../../components/HowDoesItWork/HowDoesItWork';
import SignUpGetStarted from '../../components/SignUpGetStarted';
import { auth, db } from '../../firebase';
import customerQueries from '../../hooks/queries/customer';
import bg2 from '../../images/bg2.jpg';
import WhiteLogo from '../../images/whiteLogo.svg';
import CustomInput from '../../shared/CustomInput';
import { HookedDateInput } from '../../components/HookedDateInput';
import { LOCATIONS, useViewport } from '../../utils';
import { signupSchema } from '../../utils/schemas';
import { format } from 'date-fns';

const phoneStyles = {
  width: '100%',
  padding: '8px 12px',
  lineHeight: '1.5',
  borderWidth: '1px',
  borderColor: 'gray.200',
  backgroundColor: '#fff',
  transition: 'border-color 0.2s, box-shadow 0.2s',
  outline: 'none',
  boxSizing: 'border-box',
  align: 'start',
  paddingInlineStart: '8px',
  paddingInlineEnd: '8px',
  paddingTop: '20px',
  paddingBottom: '20px',
  color: '#718096',
};

const addUser = async (uid, aid) => {
  const usersRef = doc(db, 'users', uid);
  await setDoc(usersRef, { aid }, { merge: true });
};

function SignUp() {
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState();
  const [show, setShow] = useState(false);
  const [showConfPass, setShowConfPass] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [queryParameters] = useSearchParams();
  const handleClick = () => setShow(!show);
  const { isMobile, width } = useViewport();
  const navigate = useNavigate();
  const toast = useToast();

  const {
    register,
    handleSubmit,
    control,
    formState: { isSubmitting, errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(signupSchema),
  });

  const { mutateAsync: createUser } = customerQueries.useCreateCustomer();
  const { mutateAsync: sendEmailVerification } =
    customerQueries.useSendEmailVerification();

  const onSubmit = async ({
    email,
    first_name,
    last_name,
    phone_number,
    birth_date,
    favorite_location,
    password,
    confirm_password,
    email_notification,
    sms_notification,
    accept_marketing_sms,
    terms_and_conditions,
  }) => {
    try {
      if (!terms_and_conditions) {
        setError('You must accept the terms and conditions.');
      } else if (password !== confirm_password) {
        setError('Passwords do not match.');
      } else {
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          email,
          password
        );
        const user = userCredential?.user;
        if (user) {
          const body = {
            id: email,
            first_name,
            last_name,
            email,
            birth_date: birth_date ? format(new Date(birth_date), 'yyyy-MM-dd') : undefined,
            language: 'english',
            handler: `${first_name}.${last_name}`,
            mobile_phone: phone_number.replace(/[()\s-]/g, ''),
            accept_sms: sms_notification,
            accept_email: email_notification,
            favorite_location: favorite_location,
            accept_marketing_sms,
          };
          const referralCode = queryParameters.get('rCode');
          const res = await createUser({
            body,
            rCode: referralCode,
          });

          if (res) {
            try {
              await addUser(userCredential?.user.uid, email);
              await signOut(auth);
              await sendEmailVerification({
                userId: res.id,
              });
              navigate('/email/verification');
            } catch (err) {
              toast({
                title: 'Error',
                description:
                  'Failed to send verification email. Please try again later.',
                status: 'error',
                duration: 5000,
                isClosable: true,
              });
            }
          }
        }
      }
    } catch (error) {
      if (error.code?.includes('email-already-in-use')) {
        toast({
          title: 'Account already exists! Sign in or use a different email address.',
          duration: 5000,
          position: isMobile ? 'bottom' : 'bottom-right',
          isClosable: true,
          status: 'error',
        });
      } else {
        toast({
          title: 'There was an error during sign up.',
          status: 'error',
          duration: 9000,
          isClosable: true,
        });
      }
      console.log(error);
      setError('There was an error during sign up.');
    }
  };

  const onSubmitErr = () => {
    setError('There was an error during sign up.');
  };

  return (
    <Container
      w="100%"
      h={['auto', 'auto', 'auto', '100vh']}
      maxW="initial"
      p={[5, 5, 5, 10]}
      backgroundColor="white"
      backgroundImage={bg2}
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
      position="relative"
    >
      {isMobile && (
        <Image
          w={100}
          src={WhiteLogo}
          top={10}
          left={10}
          marginBottom={5}
        />
      )}
      {isMobile && (
        <SignUpGetStarted
          titleWeight={400}
          subtitleWeight={250}
          width={'100%'}
          isMobile={isMobile}
        />
      )}
      <Center h="100%" mb={[5, 5, 5, 5]}>
        {!isMobile && (
          <Image
            w={183}
            src={WhiteLogo}
            position="absolute"
            top={10}
            right={10}
          />
        )}
        <VStack
          w={['90%', '90%', '90%', '45%']}
          p={[5, 5, 5, 10]}
          backgroundColor="white"
        >
          <form
            onSubmit={handleSubmit(onSubmit, onSubmitErr)}
            style={{ width: '100%' }}
          >
            <VStack alignItems="start" w="100%" gap={5}>
              <Text
                fontWeight={700}
                fontSize={[
                  'x-large',
                  'x-large',
                  'x-large',
                  'xxx-large',
                ]}
                color="brand.red"
                borderLeftColor="brand.red"
                borderLeftWidth={4}
                paddingLeft={2}
                justifySelf="start"
              >
                Sign Up
              </Text>
              <FormControl
                isRequired={true}
                isInvalid={errors.email}
              >
                <CustomInput
                  name="email"
                  register={register}
                  placeholder="Email Address*"
                />
                <FormErrorMessage>
                  {errors.email?.message}
                </FormErrorMessage>
              </FormControl>

              <HStack alignSelf="stretch" alignItems={'unset'}>
                <FormControl
                  isRequired={true}
                  isInvalid={errors.first_name}
                >
                  <CustomInput
                    name="first_name"
                    register={register}
                    placeholder="First Name*"
                  />
                  <FormErrorMessage>
                    {errors.first_name?.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl
                  isRequired={true}
                  isInvalid={errors.last_name}
                >
                  <CustomInput
                    name="last_name"
                    register={register}
                    placeholder="Last Name*"
                  />
                  <FormErrorMessage>
                    {errors.last_name?.message}
                  </FormErrorMessage>
                </FormControl>
              </HStack>
              <Stack
                alignSelf="stretch"
                direction={[
                  'column',
                  'column',
                  'column',
                  'row',
                ]}
                gap={[5, 5, 5, 2]}
                alignItems={'unset'}
              >
                <FormControl
                  isRequired={true}
                  isInvalid={errors.phone_number}
                  isFocused={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                  fontSize={[
                    'small',
                    'small',
                    'small',
                    'medium',
                  ]}
                >
                  <Controller
                    control={control}
                    name="phone_number"
                    render={({ field }) => (
                      //This input was styled to match the chakra ui one
                      <PatternFormat
                        style={{
                          border: errors.phone_number
                            ? '1px solid #E53E3E'
                            : isFocused
                            ? '1px solid #3182CE'
                            : '1px solid #E2E8F0',
                          boxShadow:
                            errors.phone_number
                              ? '0 0 0 1px #E53E3E'
                              : isFocused
                              ? '0 0 0 1px #3182CE'
                              : 'none',
                          height:
                            isMobile && width <= 600
                              ? '40px'
                              : '60px',
                          ...phoneStyles,
                        }}
                        {...field}
                        format="+1 (###) ###-####"
                        allowEmptyFormatting
                        placeholder="Phone Number*"
                        mask="_"
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.phone_number?.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl>
                  <HookedDateInput
                    name="birth_date"
                    control={control}
                    placeholder="Date of Birth"
                    color={'gray.500'}
                    register={register}
                  />
                  <Text color="brand.red" fontSize="sm">
                    {errors.birth_date?.message}
                  </Text>
                </FormControl>
              </Stack>
              <FormControl>
                <HookedSelect
                  name="favorite_location"
                  control={control}
                  options={LOCATIONS}
                  fontSize={[
                    'small',
                    'small',
                    'small',
                    'medium',
                  ]}
                  placeholder="Favorite Location"
                />
              </FormControl>

              <FormControl
                isRequired={true}
                isInvalid={errors.password}
              >
                <InputGroup size="md">
                  <CustomInput
                    name={'password'}
                    pr="4.5rem"
                    type={show ? 'text' : 'password'}
                    placeholder="Password*"
                    register={register}
                  />

                  <InputRightElement
                    width="4.5rem"
                    h={['40px', '40px', '40px', '60px']}
                  >
                    <Button
                      variant="ghost"
                      h="1.75rem"
                      size="sm"
                      onClick={handleClick}
                    >
                      {show ? (
                        <ViewIcon />
                      ) : (
                        <ViewOffIcon />
                      )}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>
                  {errors.password?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl
                isRequired={true}
                isInvalid={errors.confirm_password}
              >
                <InputGroup size="md">
                  <CustomInput
                    name={'confirm_password'}
                    pr="4.5rem"
                    type={
                      showConfPass ? 'text' : 'password'
                    }
                    placeholder="Confirm Password*"
                    register={register}
                  />

                  <InputRightElement
                    width="4.5rem"
                    h={['40px', '40px', '40px', '60px']}
                  >
                    <Button
                      variant="ghost"
                      h="1.75rem"
                      size="sm"
                      onClick={() => {
                        setShowConfPass(!showConfPass);
                      }}
                    >
                      {showConfPass ? (
                        <ViewIcon />
                      ) : (
                        <ViewOffIcon />
                      )}
                    </Button>
                  </InputRightElement>
                </InputGroup>
                <FormErrorMessage>
                  {errors.confirm_password?.message}
                </FormErrorMessage>
              </FormControl>

              <Text
                fontSize={['small', 'small', 'small', 'medium']}
                color="brand.green"
              >
                How would you prefer to communicate with us?
              </Text>
              <HStack alignItems={'unset'}>
                <FormControl
                  isInvalid={
                    errors.email_notification ||
                    errors.sms_notification
                  }
                >
                  <HookedCheckbox
                    name="email_notification"
                    control={control}
                    label="Email"
                  />
                  <FormErrorMessage width={'max-content'}>
                    {errors.email_notification?.message ||
                      errors.sms_notification?.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl
                  isInvalid={
                    errors.sms_notification ||
                    errors.email_notification
                  }
                >
                  <HookedCheckbox
                    name="sms_notification"
                    control={control}
                    label="Text"
                  />
                </FormControl>
              </HStack>
              <FormControl isInvalid={errors.accept_marketing_sms} >
                <HookedCheckbox
                  name="accept_marketing_sms"
                  control={control}
                  label="I agree to receive SMS marketing communications from Red Door. "
                />
              </FormControl>
              <FormControl
                isInvalid={errors.terms_and_conditions}
                isRequired={true}
              >
                <HookedCheckbox
                  control={control}
                  name="terms_and_conditions"
                  label="I accept the terms and conditions"
                  textDecoration="underline"
                  isLink={true}
                  link="#/terms"
                />
                <FormErrorMessage>
                  {errors.terms_and_conditions?.message}
                </FormErrorMessage>
              </FormControl>
            </VStack>
            <Button
              w="100%"
              type="submit"
              my={[7, 7, 7, 7]}
              alignSelf="stretch"
              size="md"
              isLoading={isSubmitting}
            >
              Sign Up
            </Button>
            <VStack>
              <Link
                textAlign={'center'}
                color="brand.green"
                href="/#login"
                fontWeight={500}
              >
                Already have an account?
                <br />
                Sign In
              </Link>
            </VStack>
          </form>
        </VStack>
        {!isMobile && (
          <SignUpGetStarted
            titleWeight={700}
            subtitleWeight={450}
            width={'10%'}
            isMobile={isMobile}
          />
        )}
      </Center>
      <HowDoesItWork showJoinBtn={false} />
    </Container>
  );
}

export default SignUp;
