export const renderSlides = (slidesData, width) => {
    let numberOfSlides = 1;

    switch (true) {
        case width >= 1720:
            numberOfSlides = slidesData?.length < 5 ? slidesData?.length : 5;
            break;
        case width >= 1224:
            numberOfSlides = slidesData?.length < 4 ? slidesData?.length : 4;
            break;
        case width >= 800:
            numberOfSlides = slidesData?.length < 3 ? slidesData?.length : 3;
            break;
        case width >= 600:
            numberOfSlides = slidesData?.length < 2 ? slidesData?.length : 2;
            break;
        default:
            numberOfSlides = 1;
            break;
    }

    return numberOfSlides;
};
