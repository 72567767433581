import {
  Button,
  Container,
  Flex,
  FormControl,
  FormErrorMessage,
  HStack,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { PatternFormat } from 'react-number-format';
import { useNavigate } from 'react-router-dom';
import { HookedCheckbox } from './components/HookedCheckbox';
import { HookedSelect } from './components/HookedSelect';
import customerQueries from './hooks/queries/customer';
import welcomeBannerBg from './images/welcome-banner-bg.jpg';
import CustomInput from './shared/CustomInput';
import { HookedDateInput } from './components/HookedDateInput';
import FloatingLabel from './shared/FloatingLabel';
import { UserContext } from './UserContext';
import { LOCATIONS, useViewport } from './utils';
import { profileUpdateSchema } from './utils/schemas';

const phoneStyles = {
  width: '100%',
  padding: '8px 12px',
  lineHeight: '1.5',
  borderWidth: '1px',
  borderColor: 'gray.200',
  backgroundColor: '#fff',
  transition: 'border-color 0.2s, box-shadow 0.2s',
  outline: 'none',
  boxSizing: 'border-box',
  align: 'start',
  paddingInlineStart: '8px',
  paddingInlineEnd: '8px',
  paddingTop: '30px',
  paddingBottom: '20px',
};

function ProfileManagement() {
  const { user } = useContext(UserContext);
  const { mutate: updateCustomer } = customerQueries.useUpdateCustomer();
  const { data: fetchedUser, refetch } = customerQueries.useCustomer(
    user?.a_id ?? '',
    {
      retryOnMount: true,
    }
  );
  const [isReadOnlyBirthDate, setIsReadOnlyBirthDate] = useState(false);
  const navigate = useNavigate();
  const { isMobile, width } = useViewport();
  const [isFocused, setIsFocused] = useState(false);
  const toast = useToast();
  const {
    register,
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    mode: 'onChange',
    resolver: yupResolver(profileUpdateSchema),
  });

  useEffect(() => {
    if (fetchedUser) {
      setIsReadOnlyBirthDate(fetchedUser.birth_date);
      reset({
        email: fetchedUser.email,
        first_name: fetchedUser.first_name,
        last_name: fetchedUser.last_name,
        phone_number: fetchedUser?.mobile_phone?.replace(
          /^(\+\d)(\d{3})(\d{3})(\d{4})$/,
          '$1 ($2) $3-$4'
        ) || '',
        birth_date: fetchedUser.birth_date,
        favorite_location: fetchedUser.properties.favorite_location,
        sms_notification: fetchedUser.properties.accept_sms,
        email_notification: fetchedUser.properties.accept_email,
        accept_marketing_sms: fetchedUser.properties.accept_marketing_sms,
      });
    }
  }, [fetchedUser, reset]);

  const onSubmit = async (formData) => {
    const body = {
      id: user?.a_id,
      first_name: formData.first_name,
      last_name: formData.last_name,
      mobile_phone: formData.phone_number.replace(/[()\s-]/g, ''),
      favorite_location: formData.favorite_location,
      accept_sms: formData.sms_notification,
      accept_email: formData.email_notification,
      accept_marketing_sms: formData.accept_marketing_sms,
    };
    if (fetchedUser) {
      if (!fetchedUser.birth_date) {
        body.birth_date = formData.birth_date?.toISOString().split('T')[0];
      }
    }

    updateCustomer(
      { body },
      {
        onSuccess: (data) => {
          toast({
            title: 'Profile saved successfully!',
            status: 'success',
            duration: 5000,
            isClosable: true,
            position: isMobile ? 'bottom' : 'bottom-right',
            variant: 'custom',
          });
          refetch();
        },
      }
    );
  };

  const onSubmitErr = () => {
    console.log('There was an error during sign up.');
  };

  const communicationWay = () => {
    return fetchedUser?.properties?.accept_email &&
      fetchedUser?.properties?.accept_sms
      ? 'Email & Text'
      : fetchedUser?.properties?.accept_email
      ? 'Email'
      : 'Text';
  };

  const renderButtons = (styles) => {
    return (
      <>
        <Button
          mt={[0, 10]}
          alignSelf="end"
          size="lg"
          _hover={{
            transform: 'scale(1.05)',
            transition: 'all 0.3s ease',
          }}
          _active={{
            bg: '#C4201D',
          }}
          type="submit"
          {...styles}
        >
          Save and Verify
        </Button>
        <Button
          mt={[0, 10]}
          alignSelf="end"
          size="lg"
          backgroundColor={'brand.green'}
          onClick={() => navigate('/')}
          _hover={{
            transform: 'scale(1.05)',
            transition: 'all 0.3s ease',
          }}
          _active={{
            bg: '#C4201D',
          }}
          {...styles}
        >
          Back to rewards
        </Button>
      </>
    );
  };

  return (
    <Container
      w="100%"
      h="100vh"
      maxW="initial"
      p={[5, 5, 5, 10]}
      backgroundColor="white"
      position="relative"
      backgroundImage={welcomeBannerBg}
      backgroundRepeat="no-repeat"
      backgroundSize="cover"
    >
      <VStack
        p={[5, 5, 5, 10]}
        h={'100%'}
        backgroundColor="white"
        w="100%"
        overflow={'auto'}
      >
        <Flex w="100%" mb={['3', '5', '5', '10']}>
          <Text
            fontWeight={700}
            fontSize={[
              'x-large',
              'x-large',
              'x-large',
              'xxx-large',
            ]}
            color="brand.red"
          >
            Account Management
          </Text>
        </Flex>
        {isMobile && width <= 600 && (
          <VStack alignItems="start" width={'100%'} mb={[2, 1, 1, 1]}>
            <Text
              fontSize={['small']}
              color="brand.green"
              textAlign="left"
              fontWeight={600}
            >
              Personal Information
            </Text>
            <Text
              color="brand.green"
              fontSize={['small']}
              textAlign="left"
              fontWeight={400}
            >
              This information is private and will not be shared
              with other players. Read the privacy notice anytime!
            </Text>
          </VStack>
        )}
        <HStack width={'100%'}>
          {(!isMobile || width >= 600) && (
            <VStack
              key={'personal-info-container'}
              width={'45%'}
              height={'100%'}
            >
              <VStack
                key={'personal-info'}
                alignItems="start"
                height={'100%'}
              >
                <VStack alignItems="start" flex={'auto'}>
                  <Text
                    fontSize={['16px', 'xx-large']}
                    color="brand.green"
                    textAlign="left"
                    fontWeight={500}
                  >
                    Personal Information
                  </Text>
                  <Text
                    color="brand.green"
                    fontSize={['large']}
                    textAlign="left"
                    fontWeight={400}
                    width={'70%'}
                  >
                    This information is private and will not
                    be shared with other players. Read the
                    privacy notice anytime!
                  </Text>
                </VStack>
                <VStack alignItems="start" gap={10}>
                  {' '}
                  <Text
                    fontSize={['16px', 'xx-large']}
                    color="brand.green"
                    textAlign="left"
                  >
                    Favorite Location:{' '}
                    {fetchedUser?.properties
                      ?.favorite_location
                      ? fetchedUser?.properties
                          ?.favorite_location
                      : 'None'}
                  </Text>
                  <Text
                    fontSize={['16px', 'xx-large']}
                    color="brand.green"
                    textAlign="left"
                  >
                    Preferred Communication:{' '}
                    {communicationWay()}
                  </Text>
                </VStack>
              </VStack>
            </VStack>
          )}
          <VStack w={['100%', '50%']}>
            <form
              onSubmit={handleSubmit(onSubmit, onSubmitErr)}
              style={{ width: '100%' }}
            >
              <FormControl isInvalid={errors.email}>
                <FloatingLabel>EMAIL ADRESS</FloatingLabel>
                <CustomInput
                  name="email"
                  register={register}
                  withLabel={true}
                  disabled={true}
                />

                <FormErrorMessage>
                  {errors.email?.message}
                </FormErrorMessage>
              </FormControl>
              <HStack
                alignSelf="stretch"
                alignItems={'unset'}
                my={3}
              >
                <FormControl isInvalid={errors.first_name}>
                  <FloatingLabel>FIRST NAME</FloatingLabel>
                  <CustomInput
                    name="first_name"
                    register={register}
                    withLabel={true}
                  />
                  <FormErrorMessage>
                    {errors.first_name?.message}
                  </FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={errors.last_name}>
                  <FloatingLabel>LAST NAME</FloatingLabel>
                  <CustomInput
                    name="last_name"
                    register={register}
                    withLabel={true}
                  />
                  <FormErrorMessage>
                    {errors.last_name?.message}
                  </FormErrorMessage>
                </FormControl>
              </HStack>
              <HStack alignSelf="stretch" alignItems={'unset'}>
                <FormControl
                  isInvalid={errors.phone_number}
                  isFocused={() => setIsFocused(true)}
                  onBlur={() => setIsFocused(false)}
                  fontSize={[
                    'small',
                    'small',
                    'small',
                    'medium',
                  ]}
                >
                  <FloatingLabel>PHONE NUMBER</FloatingLabel>
                  <Controller
                    control={control}
                    name="phone_number"
                    render={({ field }) => (
                      //This input was styled to match the chakra ui one
                      <PatternFormat
                        style={{
                          border: errors.phone_number
                            ? '1px solid #E53E3E'
                            : isFocused
                            ? '1px solid #3182CE'
                            : '1px solid #E2E8F0',
                          boxShadow:
                            errors.phone_number
                              ? '0 0 0 1px #E53E3E'
                              : isFocused
                              ? '0 0 0 1px #3182CE'
                              : 'none',
                          height:
                            width <= 992
                              ? '40px'
                              : '60px',
                          ...phoneStyles,
                        }}
                        {...field}
                        format="+1 (###) ###-####"
                        allowEmptyFormatting
                        mask="_"
                      />
                    )}
                  />
                  <FormErrorMessage>
                    {errors.phone_number?.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl>
                  <HookedDateInput
                    name="birth_date"
                    control={control}
                    placeholder="DATE OF BIRTH"
                    color={'gray.500'}
                    register={register}
                    disabled={isReadOnlyBirthDate}
                  />
                  <Text color="brand.red" fontSize="sm">
                    {errors.birth_date?.message}
                  </Text>
                </FormControl>
              </HStack>
              <FormControl>
                <HookedSelect
                  name="favorite_location"
                  control={control}
                  options={LOCATIONS}
                  withLabel={true}
                  defaultValue={
                    fetchedUser?.properties
                      ?.favorite_location
                  }
                  defaultChecked={
                    fetchedUser?.properties
                      ?.favorite_location
                  }
                />
              </FormControl>
              <Text
                fontWeight={400}
                fontSize={['small', 'small', 'small', 'medium']}
                pb={1}
              >
                Communication from Red Door Escape Room
              </Text>
              <HStack alignItems={'start'}>
                <FormControl
                  isInvalid={
                    errors.email_notification ||
                    errors.sms_notification
                  }
                  width={'auto'}
                  mr={5}
                >
                  <HookedCheckbox
                    name="email_notification"
                    control={control}
                    label="Email"
                  />
                  <FormErrorMessage width={'max-content'}>
                    {errors.email_notification?.message ||
                      errors.sms_notification?.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl
                  isInvalid={
                    errors.sms_notification ||
                    errors.email_notification
                  }
                >
                  <HookedCheckbox
                    name="sms_notification"
                    control={control}
                    label="Text"
                  />
                </FormControl>
              </HStack>
              <FormControl isInvalid={errors.accept_marketing_sms} >
                <HookedCheckbox
                  name="accept_marketing_sms"
                  control={control}
                  label="I agree to receive SMS marketing communications from Red Door. "
                />
              </FormControl>
              {!isMobile || width >= 600 ? (
                <HStack gap={4}>{renderButtons()}</HStack>
              ) : (
                <VStack align={'center'} pt={4}>
                  {renderButtons({
                    width: '100%',
                    size: 'md',
                    fontSize: 'medium',
                  })}
                </VStack>
              )}
            </form>
          </VStack>
        </HStack>
      </VStack>
    </Container>
  );
}

export default ProfileManagement;
