import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    Center,
    Container,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Image,
    InputGroup,
    InputRightElement,
    Link,
    Text,
    useToast,
    VStack,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { confirmPasswordReset } from 'firebase/auth';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import 'yup-phone-lite';
import { auth } from '../../firebase';
import bg2 from '../../images/bg2.jpg';
import WhiteLogo from '../../images/whiteLogo.svg';
import CustomInput from '../../shared/CustomInput';
import { useViewport } from '../../utils';
import { resetPasswordSchema } from '../../utils/schemas';

const ConfirmPassword = () => {
    const { isMobile } = useViewport();

    const [show, setShow] = useState(false);
    const [showConfPass, setShowConfPass] = useState(false);

    const {
        register,
        handleSubmit,
        formState: { isSubmitting, errors },
    } = useForm({
        mode: 'onChange',
        resolver: yupResolver(resetPasswordSchema),
    });
    const toast = useToast();

    const onSubmit = async (formData) => {
        const hash = document.location.hash;
        const queryList = hash.split('&');
        let code = '';
        queryList.forEach((q) => {
            if (q.includes('oobCode')) {
                code = q.split('=')[1];
            }
        });
        if (code) {
            try {
                await confirmPasswordReset(auth, code, formData.password);
                toast({
                    title: 'Password reset successfully!',
                    status: 'success',
                });
                setTimeout(() => {
                    window.location.hash = '/login';
                }, 300);
            } catch (error) {
                toast({
                    title: 'Reset password link expired!',
                    description: (
                        <Box>
                            Click the
                            <Link
                                href="#/reset/password"
                                cursor="pointer"
                                textDecoration={'underline'}
                                mx={1}
                            >
                                link
                            </Link>
                            to resend an email.
                        </Box>
                    ),
                    status: 'error',
                    duration: 9000,
                    isClosable: true,
                });
            }
        } else {
            toast({ title: 'Bad link, re-send email!', status: 'error' });
        }
    };

    const onSubmitError = (error) => {
        console.log('An error has occured', error);
    };

    return (
        <Container
            w="100%"
            h={'100vh'}
            maxW="initial"
            p={[6, 6, 6, 6]}
            backgroundColor="white"
            backgroundImage={bg2}
            backgroundRepeat="no-repeat"
            backgroundSize="cover"
            position="relative"
        >
            {isMobile && (
                <Image
                    w={100}
                    src={WhiteLogo}
                    top={10}
                    left={10}
                    marginBottom={5}
                />
            )}
            <Center h={['90%', '90%', '90%', '100%']} mb={[5, 5, 5, 5]}>
                {!isMobile && (
                    <Image
                        w={183}
                        src={WhiteLogo}
                        position="absolute"
                        top={10}
                        left={10}
                    />
                )}
                <VStack
                    w={['90%', '70%', '55%', '45%', '30%']}
                    h={['85%', '70%', '70%', '65%', '65%']}
                    justifyContent={'center'}
                    p={[4, 7, 7, 7, 7]}
                    backgroundColor="white"
                >
                    <form
                        onSubmit={handleSubmit(onSubmit, onSubmitError)}
                        style={{ width: '90%' }}
                    >
                        <VStack alignItems="center" w="100%">
                            <Box pb={[2, 4, 4, 4]} pt={[2, 4, 4, 4]}>
                                <Text
                                    fontWeight={700}
                                    fontSize={[
                                        'x-large',
                                        'x-large',
                                        'x-large',
                                        'xx-large',
                                    ]}
                                    color="brand.green"
                                    justifySelf="center"
                                    align="center"
                                >
                                    Create your password
                                </Text>
                                <Text
                                    color="brand.green"
                                    fontSize="medium"
                                    align="center"
                                    p={[2, 2, 2, 2]}
                                >
                                    Please enter a new password for your
                                    account.
                                </Text>
                            </Box>
                            <FormControl
                                isRequired={true}
                                isInvalid={errors.password}
                            >
                                <FormLabel fontWeight={700}>
                                    New Password
                                </FormLabel>
                                <InputGroup>
                                    <CustomInput
                                        name="password"
                                        type={show ? 'text' : 'password'}
                                        register={register}
                                        placeholder="New Password"
                                        pl={[4, 4, 4, 4]}
                                        borderRadius="2px"
                                        borderColor="lightGray"
                                        h={['60px', '60px', '60px', '60px']}
                                        fontSize={[
                                            'medium',
                                            'medium',
                                            'medium',
                                            'medium',
                                        ]}
                                    />

                                    <InputRightElement
                                        width="4.5rem"
                                        h={['60px', '60px', '60px', '60px']}
                                    >
                                        <Button
                                            variant="ghost"
                                            h="1.75rem"
                                            size="sm"
                                            onClick={() => {
                                                setShow(!show);
                                            }}
                                        >
                                            {show ? (
                                                <ViewIcon />
                                            ) : (
                                                <ViewOffIcon />
                                            )}
                                        </Button>
                                    </InputRightElement>
                                </InputGroup>
                                <FormErrorMessage>
                                    {errors.password?.message}
                                </FormErrorMessage>
                            </FormControl>
                            <FormControl
                                isRequired={true}
                                isInvalid={errors.confirmPassword}
                            >
                                <FormLabel fontWeight={700}>
                                    Confirm Password
                                </FormLabel>
                                <InputGroup>
                                    <CustomInput
                                        type={
                                            showConfPass ? 'text' : 'password'
                                        }
                                        name="confirmPassword"
                                        register={register}
                                        placeholder="Confirm Password"
                                        pl={[4, 4, 4, 4]}
                                        borderRadius="2px"
                                        borderColor="lightGray"
                                        h={['60px', '60px', '60px', '60px']}
                                        fontSize={[
                                            'medium',
                                            'medium',
                                            'medium',
                                            'medium',
                                        ]}
                                    />

                                    <InputRightElement
                                        width="4.5rem"
                                        h={['60px', '60px', '60px', '60px']}
                                    >
                                        <Button
                                            variant="ghost"
                                            h="1.75rem"
                                            size="sm"
                                            onClick={() => {
                                                setShowConfPass(!showConfPass);
                                            }}
                                        >
                                            {showConfPass ? (
                                                <ViewIcon />
                                            ) : (
                                                <ViewOffIcon />
                                            )}
                                        </Button>
                                    </InputRightElement>
                                </InputGroup>
                                <FormErrorMessage>
                                    {errors.confirmPassword?.message}
                                </FormErrorMessage>
                            </FormControl>
                            <Button
                                w="100%"
                                type="submit"
                                textTransform="initial"
                                my={[4, 4, 4, 4]}
                                p={[2, 2, 2, 2]}
                                borderRadius="2px"
                                alignSelf="stretch"
                                size="lg"
                                fontSize={['large', 'large', 'large', 'large']}
                                h={['60px', '60px', '60px', '60px']}
                                isLoading={isSubmitting}
                            >
                                Reset Password
                            </Button>
                        </VStack>
                    </form>
                </VStack>
            </Center>
        </Container>
    );
};

export { ConfirmPassword };
