import 'react-slideshow-image/dist/styles.css';
import { Center } from '@chakra-ui/react';

import RewardItem from './RewardItem/RewardItem';

function RewardItemWrap({ ...props }) {
    return (
        <>
            <Center>
                <RewardItem {...props} />
            </Center>
        </>
    );
}

export default RewardItemWrap;
