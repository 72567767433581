import { CloseIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    Flex,
    Grid,
    GridItem,
    Heading,
    IconButton,
    Image,
    Modal,
    ModalContent,
    ModalOverlay,
    Spacer,
    Text,
    useBreakpointValue,
    useDisclosure,
    VStack,
} from '@chakra-ui/react';
import emptyBadge from './images/empty-badge.svg';
import redBadge from './images/redBadge.svg';
import { useViewport } from './utils';

import { useState } from 'react';
import ReactPaginate from 'react-paginate';
import customerQueries from './hooks/queries/customer';
import rewardsQueries from './hooks/queries/rewards';

const BadgeRendering = ({ image, name, isClaimed, timesClaimed }) => (
    <Box w={[180, 180, 227, 341]} h={[194, 194, 194, 292]} position="relative">
        <Image
            zIndex={1}
            width="100%"
            height="100%"
            src={isClaimed ? redBadge : emptyBadge}
        />
        <Grid
            zIndex={2}
            position="absolute"
            top="22%"
            right={0}
            bottom="5%"
            left={0}
            gap={2}
            templateRows="1fr 1fr 1fr"
            justifyItems="center"
            alignItems={['center', 'center', 'baseline', 'baseline']}
        >
            <GridItem alignSelf="center !important">
                <Text
                    textAlign="center"
                    maxW={['90px', '90px', '140px', '160px']}
                    fontSize={['xs', 'xs', 'smaller', 'larger']}
                    mt={['10px', '10px', '10px', 0]}
                    color="white"
                    fontWeight={700}
                >
                    {name?.toUpperCase()}
                </Text>
            </GridItem>
            <GridItem position="relative">
                <Image boxSize={['44px', '44px', '65px', '96px']} src={image} />

                {timesClaimed > 1 && (
                    <Box
                        position="absolute"
                        bottom={['-14px', '-15px', '-23px', '-35px']}
                        right={['-20px', '-20px', '-17px', '-35px']}
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        width={['20px', '25px', '25px', '40px']}
                        height={['20px', '25px', '25px', '40px']}
                        borderRadius="50%"
                        backgroundColor="brand.green"
                        color="white"
                        fontSize={['x-small', 'small', 'small', 'medium']}
                    >
                        {timesClaimed}x
                    </Box>
                )}
            </GridItem>
        </Grid>
    </Box>
);

function Badge(props) {
    const { isOpen, onOpen, onClose } = useDisclosure();

    return (
        <>
            <Box
                borderColor="gray.200"
                borderBottomRadius={0}
                borderWidth={2}
                position="relative"
            >
                <BadgeRendering {...props} />
                <Button
                    onClick={onOpen}
                    position="absolute"
                    variant="green"
                    size="sm"
                    right={1}
                    bottom={1}
                    zIndex={100}
                >
                    Info
                </Button>
            </Box>
            <Modal
                isOpen={isOpen}
                onClose={onClose}
                size={['full', 'full', 'full', 'md']}
            >
                <ModalOverlay />
                <ModalContent
                    borderRadius={0}
                    background="none"
                    bgGradient="radial(rgba(0, 135, 170, 0.9), rgba(0, 54, 68, 0.9))"
                    opacity={0.9}
                >
                    <Flex
                        direction={['column', 'column', 'row', 'column']}
                        py={10}
                        position="relative"
                    >
                        <VStack>
                            <Text
                                fontSize={['4xl', '4xl', '2xl', '4xl']}
                                fontWeight={700}
                                color="white"
                                width="300px"
                                textAlign="center"
                            >
                                {props?.isClaimed
                                    ? 'Achievement Unlocked'
                                    : 'Locked'}
                            </Text>
                            <BadgeRendering {...props} />
                        </VStack>
                        <VStack
                            w="100%"
                            alignItems="start"
                            px={10}
                            justifyContent="center"
                        >
                            <Text fontSize="26px" color="white">
                                Description
                            </Text>
                            <Text color="white">{props?.description}</Text>
                            <Text fontSize="26px" color="white">
                                Details
                            </Text>
                            <Text color="white">
                                {props?.isClaimed
                                    ? `You were awarded this badge on ${props?.timestamp}`
                                    : "You haven't been awarded with this badge yet"}
                            </Text>
                        </VStack>
                        <IconButton
                            isRound={true}
                            variant="solid"
                            backgroundColor="white"
                            aria-label="Done"
                            fontSize="16px"
                            size="sm"
                            position="absolute"
                            top={2}
                            right={2}
                            icon={<CloseIcon boxSize={2} color="brand.green" />}
                            onClick={onClose}
                        />
                    </Flex>
                </ModalContent>
            </Modal>
        </>
    );
}

const mapBadges = (data, claimedRewards) =>
    data
        ?.map((badge) => {
            const reward = claimedRewards?.find(({ id }) => id === badge?.id);
            const allClaimedRewards = claimedRewards?.filter(
                ({ id }) => id === badge?.id
            );

            const timestamp = reward?.timestamp
                ? new Intl.DateTimeFormat('en-US', {
                      month: 'short',
                      year: 'numeric',
                      day: 'numeric',
                  }).format(new Date(reward?.timestamp))
                : '';

            return {
                name: badge?.name?.en,
                image: badge?.media?.url,
                completionUnit: 'episodes',
                description: badge?.description?.en,
                isClaimed: !!reward,
                id: badge?.id,
                timestamp,
                timesClaimed: allClaimedRewards?.length,
            };
        })
        .sort((badgeA, badgeB) => badgeA.name.localeCompare(badgeB.name));

function Badges({ user }) {
    const { isMobile } = useViewport();

    const { data: badges, isLoading: isBadgesLoading } =
        rewardsQueries.useCustomerBadges(user?.a_id ?? '');

    const { data: claimedRewards, isLoading: isClaimedRewardsLoading } =
        customerQueries.useCustomerClaimedRewards(user?.a_id ?? '');

    const itemsPerPage = useBreakpointValue({
        base: 6, // Base number
        sm: 6, // Mobile view
        md: 6, // Medium screens (tablets)
        lg: 8, // Larger screens (desktops)
        xl: 8, // Extra-large screens
    });

    const [itemOffset, setItemOffset] = useState(0);
    const [currentPage, setCurrentPage] = useState(0);

    if (isBadgesLoading || isClaimedRewardsLoading)
        return <Text>Loading...</Text>;

    const claimedRewardsIds = claimedRewards?.map((reward) => ({
        id: reward?.properties?.[0]?.value?.id,
        timestamp: reward?.timestamp,
    }));
    const badgesData = mapBadges(badges, claimedRewardsIds);
    const claimedBadgesCount = badgesData?.reduce(
        (acc, badge) => acc + badge.timesClaimed,
        0
    );
    const endOffset = itemOffset + itemsPerPage;
    const currentItems = badgesData?.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(badgesData?.length / itemsPerPage);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % badgesData?.length;
        setItemOffset(newOffset);
        setCurrentPage(event.selected);
    };

    return (
        <Box mb={20}>
            <Flex
                direction={['column', 'column', 'column', 'row']}
                px={[2, 2, 2, 10]}
                py={[5, 5, 5, 10]}
                align={['center', 'center', 'center', 'initial']}
            >
                <Box textAlign={['center', 'center', 'center', 'left']}>
                    <Heading
                        as="h1"
                        color="brand.green"
                        w={['auto', 'auto', 'auto', 400]}
                        fontSize={[
                            'x-large',
                            'x-large',
                            'x-large',
                            'xxx-large',
                        ]}
                    >
                        My Badges
                    </Heading>
                    {isMobile ? (
                        <Text
                            color="brand.green"
                            w={['auto', 'auto', 'auto', 500]}
                            fontWeight={400}
                            fontSize="x-large"
                        >
                            Earn badges by completing challenges and milestones!
                        </Text>
                    ) : (
                        <Text
                            color="brand.red"
                            fontWeight={400}
                            fontSize="x-large"
                        >
                            TOTAL BADGES EARNED: {claimedBadgesCount}
                        </Text>
                    )}
                </Box>
                {!isMobile && (
                    <>
                        <Spacer />
                        <Text
                            color="brand.green"
                            fontWeight={400}
                            fontSize="x-large"
                            w={['auto', 'auto', 'auto', '40%']}
                        >
                            Earn badges as you complete challenges and
                            milestones! Each badge represents your achievements
                            and skills in various areas. Collect them all to
                            showcase your expertise and track your progress.
                        </Text>
                    </>
                )}
            </Flex>
            <Box minHeight={['400px', '650px']}>
                <Grid
                    justifyItems="center"
                    templateColumns={[
                        '1fr 1fr',
                        '1fr 1fr',
                        '1fr 1fr',
                        'repeat(4, 1fr)',
                    ]}
                    gap={[2, 2, 6, 6]}
                >
                    {currentItems?.map((badge, i) => (
                        <Badge key={i} {...badge} />
                    ))}
                </Grid>
            </Box>

            {pageCount > 1 && (
                <Flex
                    h="80px"
                    w="100%"
                    justifyContent="center"
                    border="1px solid"
                    borderColor="gray.100"
                    mt={4}
                >
                    <ReactPaginate
                        breakLabel="..."
                        nextLabel={
                            <Button
                                size={['sm', 'sm', 'sm', 'md']}
                                variant="greenBorder"
                                isDisabled={currentPage === pageCount - 1}
                                _disabled={{
                                    opacity: 0.5,
                                    cursor: 'not-allowed',
                                }}
                            >
                                Next
                            </Button>
                        }
                        onPageChange={handlePageClick}
                        pageRangeDisplayed={2}
                        pageCount={pageCount}
                        previousLabel={
                            <Button
                                size={['sm', 'sm', 'sm', 'md']}
                                variant="greenBorder"
                                isDisabled={currentPage === 0}
                                _disabled={{
                                    opacity: 0.5,
                                    cursor: 'not-allowed',
                                }}
                            >
                                Previous
                            </Button>
                        }
                        renderOnZeroPageCount={null}
                        className="react-paginate"
                        activeLinkClassName="active-link"
                    />
                </Flex>
            )}
        </Box>
    );
}

export default Badges;
