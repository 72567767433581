import { useQueryClient } from '@tanstack/react-query';
import { differenceInSeconds } from 'date-fns';
import React, { useContext, useEffect, useState } from 'react';
import YouTube from 'react-youtube';
import { UserContext } from '../UserContext/UserContext';
import { API_KEY, CUSTOMERS_URL } from '../utils';

const watchVideo = async (customerId, url, token) => {
    const res = await fetch(`${CUSTOMERS_URL}/customer/event`, {
        headers: {
            'Accept-Language': 'en',
            'Authorization': `Bearer ${token}`
        },
        method: 'POST',
        body: JSON.stringify({
            customer: customerId,
            action: 'video',
            data: {
                url,
            },
        }),
    });
    const json = await res.json();
    return json;
};

export const VideoPlayer = ({ url, refetch }) => {
    const id = url.split('v=')[1].slice(0, 11);
    const [videoDuration, setVideoDuration] = useState(0);
    const [startedAt, setStartedAt] = useState(undefined);
    const [secondsWatched, setSecondsWatched] = useState(0);
    const percent = (100 * secondsWatched) / videoDuration;
    const { user } = useContext(UserContext);
    const queryClient = useQueryClient();

    useEffect(() => {
        if (percent >= 80) {
            if (user) {
                watchVideo(user.a_id, url, user.token).then((json) => {
                    queryClient.invalidateQueries({
                        queryKey: [API_KEY.customer, user.a_id],
                        refetchActive: true,
                        refetchInactive: true,
                    });
                    setSecondsWatched(0);
                    refetch();
                });
            }
        }
    }, [percent, user, url]);

    return (
        <YouTube
            style={{ width: '100%', height: '100%' }}
            opts={{
                width: '100%',
                height: '100%',
                playerVars: {
                    autoplay: 0,
                },
            }}
            videoId={id}
            onEnd={() => {
                if (startedAt) {
                    const now = new Date();
                    const sec = Math.abs(differenceInSeconds(startedAt, now));
                    setSecondsWatched(sec + secondsWatched);
                }
            }}
            onReady={async (e) => {
                const n = await e.target.getDuration();
                setVideoDuration(n);
            }}
            onPlay={() => {
                setStartedAt(new Date());
            }}
            onPause={() => {
                if (startedAt) {
                    const now = new Date();
                    const sec = Math.abs(differenceInSeconds(startedAt, now));
                    setSecondsWatched(sec + secondsWatched);
                }
            }}
        />
    );
};
