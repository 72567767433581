import { Center, Container, Image, Text, VStack } from '@chakra-ui/react';
import 'yup-phone-lite';
import bg2 from '../../images/bg2.jpg';
import WhiteLogo from '../../images/whiteLogo.svg';
import { useViewport } from '../../utils';

const EmailVerification = () => {
    const { isMobile } = useViewport();

    return (
        <Container
            w="100%"
            h={'100vh'}
            maxW="initial"
            p={[6, 6, 6, 6]}
            backgroundColor="white"
            backgroundImage={bg2}
            backgroundRepeat="no-repeat"
            backgroundSize="cover"
            position="relative"
        >
            {isMobile && (
                <Image
                    w={100}
                    src={WhiteLogo}
                    top={10}
                    left={10}
                    marginBottom={5}
                />
            )}
            <Center h={['90%', '90%', '90%', '100%']} mb={[5, 5, 5, 5]}>
                {!isMobile && (
                    <Image
                        w={183}
                        src={WhiteLogo}
                        position="absolute"
                        top={10}
                        left={10}
                    />
                )}
                <VStack
                    w={['90%', '65%', '55%', '40%', '35%']}
                    h={['75%', '45%', '40%', '40%', '35%']}
                    p={[4, 7, 7, 7, 7]}
                    backgroundColor="white"
                    rounded={'sm'}
                    align={'center'}
                    justifyContent={'center'}
                >
                    <Text
                        align={'center'}
                        color="brand.green"
                        fontSize={[
                            'medium',
                            'large',
                            'large',
                            'large',
                            'large',
                        ]}
                        fontWeight={400}
                    >
                        Thank you for signing up! 🎉
                    </Text>
                    <Text
                        align={'center'}
                        color="brand.green"
                        fontSize={[
                            'medium',
                            'large',
                            'large',
                            'large',
                            'large',
                        ]}
                        fontWeight={400}
                    >
                        To complete your registration, please verify your email
                        address by clicking the link we just sent there.
                    </Text>
                    <Text
                        align={'center'}
                        color="brand.green"
                        fontSize={[
                            'medium',
                            'large',
                            'large',
                            'large',
                            'large',
                        ]}
                        fontWeight={400}
                    >
                        ⚠️ If you don’t see the email, check your spam folder.
                    </Text>
                    <Text
                        align={'center'}
                        color="brand.green"
                        fontSize={[
                            'medium',
                            'large',
                            'large',
                            'large',
                            'large',
                        ]}
                        fontWeight={400}
                    >
                        Once your email is verified, you’ll be able to access
                        all the features of your account.
                    </Text>
                </VStack>
            </Center>
        </Container>
    );
};

export { EmailVerification };
