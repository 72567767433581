import {
    Box,
    Center,
    Flex,
    Heading,
    Link,
    Skeleton,
    Text,
    VStack,
} from '@chakra-ui/react';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { forwardRef, useEffect, useRef } from 'react';
import { DynamicKey, DynamicProgressBar } from './components';
import customerQueries from './hooks/queries/customer';
import welcomeBannerBg from './images/welcome-banner-bg.jpg';
import { useViewport } from './utils';

// Register ScrollTrigger with GSAP
gsap.registerPlugin(ScrollTrigger);

const calcPercentage = (x, y) => Math.floor(((x / y) * 100) / 10) * 10;

const Episodes = ({ episodes, quests }) => {
    if (episodes && quests) {
        return (
            <Text
                color="white"
                fontSize="x-large"
                fontWeight={700}
                mx={['1em', '1em', 0, 0]}
            >
                You've completed {episodes} episode{episodes > 1 ? 's' : ''} and{' '}
                {quests} quest{quests > 1 ? 's' : ''}!
            </Text>
        );
    } else if (episodes && !quests) {
        return (
            <Text
                color="white"
                fontSize="x-large"
                fontWeight={700}
                mx={['1em', '1em', 0, 0]}
            >
                You've completed {episodes} episode{episodes > 1 ? 's' : ''}!
            </Text>
        );
    } else if (quests && !episodes) {
        return (
            <Text
                color="white"
                fontSize="x-large"
                fontWeight={700}
                mx={['1em', '1em', 0, 0]}
            >
                You've completed {quests} quest{quests > 1 ? 's' : ''}!
            </Text>
        );
    }
    return <></>;
};

const WelcomeBanner = forwardRef(({ user }, ref) => {
    const headingRef = useRef(null);
    const textRef = useRef(null);
    const progressBarRef = useRef(null);

    const { isMobile, width } = useViewport();
    const { data: fetchedUser, isLoading } = customerQueries.useCustomer(
        user?.a_id ?? '',
        { retryOnMount: true }
    );

    const episodesCompleted = {
        episodes: fetchedUser?.properties?.cf_episodes_completed,
        quests: fetchedUser?.properties?.cf_quests_completed,
    };

    const currentScore = fetchedUser?.tiers?.[0]?.points ?? 0;
    const nextThreshold =
        (fetchedUser?.tiers?.[0]?.points ?? 0) +
        (fetchedUser?.tiers?.[0]?.next?.points ?? 0);
    const pointsAway = fetchedUser?.tiers?.[0]?.next?.points ?? 0;

    const percentage = calcPercentage(currentScore, nextThreshold);

    useEffect(() => {
        ScrollTrigger.create({
            trigger: headingRef.current,
            onEnter: () => {
                gsap.fromTo(
                    headingRef.current,
                    { opacity: 0, y: -20 },
                    { opacity: 1, y: 0, duration: 2 }
                );
            },
        });

        ScrollTrigger.create({
            trigger: textRef.current,
            onEnter: () => {
                gsap.fromTo(
                    textRef.current,
                    { opacity: 0, y: 20 },
                    { opacity: 1, y: 0, duration: 2 }
                );
            },
        });

        ScrollTrigger.create({
            trigger: progressBarRef.current,
            onEnter: () => {
                gsap.fromTo(
                    progressBarRef.current,
                    { scaleY: 0 },
                    { scaleY: 1, transformOrigin: 'bottom', duration: 2 }
                );
            },
        });
    }, []);

    return (
        <Box>
            <Flex
                direction="column"
                justify="center"
                align="center"
                h={[700, 700, 700, 1000]}
                backgroundColor="white"
                backgroundImage={welcomeBannerBg}
                backgroundRepeat="no-repeat"
                backgroundSize={[
                    `${width}px ${width / 1.2}px`,
                    `${width}px ${width / 1.2}px`,
                    `${width}px ${width / 1.2}px`,
                    'cover',
                ]}
            >
                <Flex
                    direction={['column', 'column', 'row']}
                    w="90%"
                    p={[0, 0, 10]}
                    zIndex={2}
                >
                    <Flex w={['100%', '100%', '50%']} direction="column">
                        <Heading
                            ref={headingRef}
                            as="h1"
                            fontSize={[24, 24, 24, 50]}
                            color="white"
                        >
                            Welcome Back, {!isMobile && <br />}
                            {fetchedUser?.first_name ?? '...'}!
                        </Heading>
                        {!isMobile ? (
                            <Episodes
                                episodes={episodesCompleted?.episodes}
                                quests={episodesCompleted?.quests}
                            />
                        ) : (
                            <VStack align="start" mb={4}>
                                <Text
                                    color="white"
                                    fontSize="large"
                                    fontWeight={700}
                                >
                                    You are{' '}
                                    <Text
                                        as="span"
                                        display="inline"
                                        color="brand.red"
                                    >
                                        {Number.isNaN(pointsAway)
                                            ? '.......'
                                            : pointsAway?.toLocaleString()}{' '}
                                        points
                                    </Text>{' '}
                                    away from next tier
                                </Text>
                                <Text color="white" fontSize="large">
                                    <strong>Current Tier:</strong>{' '}
                                    {fetchedUser?.tiers?.[0]?.name ?? '...'}
                                </Text>
                                <Text color="white" fontSize="large">
                                    {fetchedUser?.tiers?.[0]?.next?.name ? (
                                        <>
                                            <strong>Next Tier:</strong>{' '}
                                            {fetchedUser?.tiers?.[0]?.next
                                                ?.name ??
                                                'Architect! Top tier cracked'}
                                        </>
                                    ) : (
                                        <strong>
                                            Architect! Top tier cracked.
                                        </strong>
                                    )}
                                </Text>
                            </VStack>
                        )}
                    </Flex>
                    <Center
                        w={['100%', '100%', '50%']}
                        py={10}
                        px={[0, 0, 10]}
                        ml={[1, 1, 1, 0]}
                        bgGradient={[
                            'linear(to-b, rgb(0, 54, 68), rgb(0, 0, 0))',
                            'linear(to-b, rgb(0, 54, 68), rgb(0, 0, 0))',
                            'linear(to-b, rgba(0, 0, 0, 0.8), rgba(0, 54, 68, 1))',
                        ]}
                    >
                        <VStack>
                            {isMobile && (
                                <Episodes
                                    episodes={episodesCompleted?.episodes}
                                    quests={episodesCompleted?.quests}
                                />
                            )}
                            <Box position="relative" ref={progressBarRef}>
                                <Box
                                    width={[115, 115, 115, 230]}
                                    height={[165, 165, 165, 330]}
                                    mt={[10, 10, 10, 0]}
                                >
                                    <DynamicKey
                                        percentage={percentage}
                                        isLoading={isLoading}
                                    />
                                </Box>
                                <Box>
                                    <VStack
                                        position="absolute"
                                        textAlign="center"
                                        align={'center'}
                                        color="white"
                                        fontWeight={700}
                                        fontSize={[
                                            'large',
                                            'large',
                                            'large',
                                            'x-large',
                                        ]}
                                        left={[-100, -100, -100, -50]}
                                        bottom={[30, 30, 30, 50]}
                                        w={100}
                                    >
                                        <>
                                            <Box
                                                width={[
                                                    '25px',
                                                    '25px',
                                                    '25px',
                                                    '40px',
                                                ]}
                                                height="3px"
                                                bg="brand.red"
                                            />
                                            {!isLoading ? (
                                                <>
                                                    {fetchedUser?.tiers?.[0]?.points?.toLocaleString()}
                                                </>
                                            ) : (
                                                <Skeleton
                                                    startColor="brand.500"
                                                    endColor="brand.red"
                                                    height="20px"
                                                    marginTop={2}
                                                />
                                            )}{' '}
                                            {isMobile ? (
                                                <Text
                                                    fontSize="medium"
                                                    textAlign="center"
                                                    color="white"
                                                >
                                                    points
                                                </Text>
                                            ) : (
                                                'points'
                                            )}
                                        </>
                                    </VStack>
                                    <VStack
                                        position="absolute"
                                        textAlign="center"
                                        align={'center'}
                                        color="white"
                                        fontWeight={700}
                                        fontSize={[
                                            'large',
                                            'large',
                                            'large',
                                            'x-large',
                                        ]}
                                        left={[110, 110, 110, '210px']}
                                        top={50}
                                        w={100}
                                    >
                                        <>
                                            <Box
                                                width={[
                                                    '25px',
                                                    '25px',
                                                    '25px',
                                                    '40px',
                                                ]}
                                                height="3px"
                                                bg="brand.red"
                                            />
                                            {!isLoading ? (
                                                nextThreshold?.toLocaleString()
                                            ) : (
                                                <Skeleton
                                                    startColor="brand.500"
                                                    endColor="brand.red"
                                                    height="20px"
                                                    marginTop={2}
                                                />
                                            )}{' '}
                                            {isMobile ? (
                                                <Text
                                                    fontSize="medium"
                                                    textAlign="center"
                                                    color="white"
                                                >
                                                    points
                                                </Text>
                                            ) : (
                                                'points'
                                            )}
                                        </>
                                    </VStack>
                                </Box>
                            </Box>
                            <VStack>
                                <Text
                                    color="white"
                                    fontSize={[
                                        'large',
                                        'large',
                                        'large',
                                        'x-large',
                                    ]}
                                    fontWeight={700}
                                >
                                    Tier Progress
                                </Text>
                                <DynamicProgressBar
                                    percentage={percentage}
                                    width={isMobile ? 100 : 'auto'}
                                />
                                {isMobile && (
                                    <Link
                                        href="#benefits"
                                        color="white"
                                        textDecoration="underline"
                                        textUnderlineOffset={2}
                                        _hover={{
                                            textDecorationColor: 'white',
                                        }}
                                        _focus={{ boxShadow: 'none' }}
                                        onClick={() => window.scrollTo(0, 0)}
                                    >
                                        View Tier Benefits & FAQs
                                    </Link>
                                )}
                            </VStack>
                            {!isMobile && (
                                <VStack>
                                    <Text
                                        color="white"
                                        fontSize="x-large"
                                        fontWeight={700}
                                        textAlign="center"
                                    >
                                        You are{' '}
                                        <Text
                                            as="span"
                                            display="inline"
                                            color="brand.red"
                                        >
                                            {Number.isNaN(pointsAway)
                                                ? '.......'
                                                : pointsAway?.toLocaleString()}{' '}
                                            points
                                        </Text>{' '}
                                        away from next tier
                                    </Text>
                                    <Text color="white" fontSize="x-large">
                                        <strong>Current Tier:</strong>{' '}
                                        {fetchedUser?.tiers?.[0]?.name ?? '...'}
                                    </Text>
                                    <Text color="white" fontSize="x-large">
                                        {fetchedUser?.tiers?.[0]?.next?.name ? (
                                            <>
                                                <strong>Next Tier:</strong>{' '}
                                                {fetchedUser?.tiers?.[0]?.next
                                                    ?.name ??
                                                    'Architect! Top tier cracked'}
                                            </>
                                        ) : (
                                            <strong>
                                                Architect! Top tier cracked.
                                            </strong>
                                        )}
                                    </Text>
                                    <Link
                                        href="#benefits"
                                        color="white"
                                        textDecoration="underline"
                                        textUnderlineOffset={2}
                                        _hover={{
                                            textDecorationColor: 'white',
                                        }}
                                        _focus={{ boxShadow: 'none' }}
                                        onClick={() => window.scrollTo(0, 0)}
                                    >
                                        View Tier Benefits & FAQs
                                    </Link>
                                </VStack>
                            )}
                        </VStack>
                    </Center>
                </Flex>
            </Flex>
        </Box>
    );
});

export default WelcomeBanner;