import { Link, ListItem, Text, UnorderedList } from '@chakra-ui/react';

export const termsText = [
    {
        label: 'Acceptance of Terms',
        text: (
            <Text color="brand.lightGray">
                By enrolling in the Red Door Escape Room Loyalty Program
                ("Program"), you agree to abide by these Terms of Use, as well
                as any other rules or guidelines provided by Red Door Escape
                Room ("Company"). Participation in the Program is conditioned
                upon acceptance of these terms, which may be updated
                periodically. Continued participation in the Program after any
                such changes constitutes your acceptance of the new terms.
            </Text>
        ),
    },
    {
        label: 'Eligibility',
        text: (
            <Text color="brand.lightGray" width={'100%'}>
                To participate in the Program, you must:
                <UnorderedList my={3} marginInlineStart={10}>
                    <ListItem>
                        Have a valid email address and mobile phone number.
                    </ListItem>
                    <ListItem>
                        Not have previously been terminated from the Program for
                        any reason.
                    </ListItem>
                </UnorderedList>
                <Text>The Program is void where prohibited by law.</Text>
                <Text mt={5}>
                    The Program is open to individuals who are at least 18 years
                    old. Employees of Red Door Escape Rooms and their immediate
                    family members are not eligible to participate in the
                    Program.
                </Text>
            </Text>
        ),
    },
    {
        label: 'Enrollment',
        text: (
            <Text color="brand.lightGray">
                You can enroll in the Program by creating an account through the
                Red Door Escape Room website, or at any Red Door Escape Room
                location. You agree to provide accurate, current, and complete
                information during the registration process and to update such
                information as necessary. You are responsible for maintaining
                the confidentiality of your account credentials and for all
                activities that occur under your account.
            </Text>
        ),
    },
    {
        label: 'Earning Points',
        text: (
            <>
                <Text color="brand.lightGray">
                    Members earn points for eligible purchases made at
                    participating Red Door Escape Room locations and online.
                    Members may also earn points from completing challenges. The
                    rate at which points are earned may vary based on the type
                    of purchase, promotions, or special offers. Points for
                    purchases are credited after you have checked in to play
                    your experience. Points earned for completing challenges are
                    credited within 24hrs. <br />
                </Text>
                <Text color="brand.lightGray">
                    Points are non-transferable and cannot be exchanged for
                    cash. Red Door Escape Room reserves the right to modify the
                    methods by which points and badges can be earned, as well as
                    the number of points and badges awarded for each activity.
                </Text>
            </>
        ),
    },
    {
        label: 'Rewards',
        text: (
            <Text color="brand.lightGray" width={'100%'}>
                Points accumulated in the Program can be redeemed for rewards as
                specified by the Company. The available rewards, as well as the
                points required for each reward, may change from time to time at
                the Company's discretion.
                <Text color="brand.lightGray" mt={3}>
                    Rewards may include, but are not limited to:
                </Text>
                <UnorderedList my={3} marginInlineStart={10}>
                    <ListItem>
                        Discounted or free escape room experiences.
                    </ListItem>
                    <ListItem>
                        Exclusive access to events or new rooms.
                    </ListItem>
                    <ListItem>Promotional merchandise.</ListItem>
                </UnorderedList>
                <Text mt={5}>
                    Once a reward is redeemed, it cannot be refunded, returned,
                    or exchanged for points or cash. Rewards may expire if not
                    used within the specified expiration window. Please refer to
                    the specific reward details for expiration information. Red
                    Door Escape Room is not responsible for any expired rewards
                    or points that were not redeemed within the given timeframe.
                </Text>
            </Text>
        ),
    },
    {
        label: 'Account Management',
        text: (
            <>
                <Text color="brand.lightGray">
                    Members are responsible for maintaining the confidentiality
                    of their account login information and for any activities
                    that occur under their account. You must promptly notify Red
                    Door Escape Room of any unauthorized use of your account.{' '}
                    <br />
                </Text>
                <Text color="brand.lightGray">
                    The Company reserves the right to terminate or suspend any
                    account that is suspected of fraudulent activity or
                    violations of these Terms of Use.
                </Text>
            </>
        ),
    },
    {
        label: 'Expiration of Points',
        text: (
            <Text color="brand.lightGray">
                Points earned through the Program will expire after 13 months of
                inactivity on your account. Inactivity is defined as no points
                being earned or redeemed within a 12-month period. Expired
                points cannot be reinstated.
            </Text>
        ),
    },
    {
        label: 'Modification or Termination of Program',
        text: (
            <>
                <Text color="brand.lightGray">
                    Red Door Escape Room reserves the right to modify, suspend,
                    or terminate the Program at any time without notice. This
                    includes, but is not limited to, changes to the points
                    structure, reward offerings, and terms of use.
                    <br />
                </Text>
                <Text color="brand.lightGray">
                    In the event of termination, members will have a limited
                    time to redeem any remaining points before they are
                    forfeited.
                </Text>
            </>
        ),
    },
    {
        label: 'Privacy',
        text: (
            <Text color="brand.lightGray">
                By enrolling in the Program, you agree to the collection, use,
                and disclosure of your personal information in accordance with
                Red Door Escape Room's Privacy Policy. Your information may be
                used to manage your account, provide you with Program benefits,
                and send you promotional materials.
            </Text>
        ),
    },
    {
        label: 'Governing Law',
        text: (
            <Text color="brand.lightGray">
                These Terms of Use are governed by the laws of the state in
                which Red Door Escape Room is headquartered, without regard to
                its conflict of law provisions. Any disputes arising under these
                Terms of Use will be resolved in the courts of that state.
            </Text>
        ),
    },
    {
        label: 'Limitation of Liability',
        text: (
            <Text color="brand.lightGray">
                Red Door Escape Rooms shall not be liable for any damages,
                losses, or expenses arising from or related to your
                participation in the Program, including but not limited to the
                loss of points or inability to redeem points.
            </Text>
        ),
    },
    {
        label: 'Contact Us',
        text: (
            <>
                <Text color="brand.lightGray">
                    If you have any questions about these Terms or the Program,
                    please contact us at
                </Text>
                <Link
                    href="mailto:corporate@reddoorescape.com"
                    isExternal
                    color="blue.600"
                >
                    corporate@reddoorescape.com
                </Link>
            </>
        ),
    },
    {
        label: 'Acknowledgment',
        text: (
            <Text color="brand.lightGray">
                By creating an account and participating in the Program, you
                acknowledge that you have read, understood, and agreed to these
                Terms and Conditions.
            </Text>
        ),
    },
];
