import { useEffect, useRef, useState } from 'react';
import './DynamicKey.css';

export const DynamicKey = ({ percentage, isLoading = false }) => {
    const loadingBlock = useRef(0);
    const [animatedPercentage, setAnimatedPercentage] = useState(10);

    useEffect(() => {
        if (!isLoading) {
            setTimeout(
                () =>
                    loadingBlock?.current?.setAttribute(
                        'class',
                        `animate-${percentage}-percent-jump`
                    ),
                50
            );
        }
    }, [percentage, isLoading]);

    useEffect(() => {
        if (isLoading) {
            // change the percentage accordingly to offer a loading view
            loadingBlock?.current?.setAttribute(
                'class',
                `animate-${animatedPercentage}-percent-jump`
            );
            if (animatedPercentage < 100) {
                setTimeout(() => {
                    setAnimatedPercentage(animatedPercentage + 10);
                }, 50);
            }
        }
    }, [isLoading, animatedPercentage]);

    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="100%"
            height="100%"
            viewBox="0 0 230 330"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M157.583 196C182.486 180.107 199 152.232 199 120.5C199 71.0705 158.929 31 109.5 31C60.0705 31 20 71.0705 20 120.5C20 152.232 36.514 180.107 61.4166 196H60V306H159V196H157.583Z"
                fill="#C2C2C2"
            />
            <g mask="url(#print)">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M157.583 196C182.486 180.107 199 152.232 199 120.5C199 71.0705 158.929 31 109.5 31C60.0705 31 20 71.0705 20 120.5C20 152.232 36.514 180.107 61.4166 196H60V306H159V196H157.583Z"
                    fill="#E82C2A"
                />
                <rect
                    ref={loadingBlock}
                    x="0"
                    y="45"
                    width="233"
                    height="275"
                    fill="white"
                    id="loading-block"
                />
            </g>
            <mask id="print">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M157.583 196C182.486 180.107 199 152.232 199 120.5C199 71.0705 158.929 31 109.5 31C60.0705 31 20 71.0705 20 120.5C20 152.232 36.514 180.107 61.4166 196H60V306H159V196H157.583Z"
                    fill="black"
                />
                <path
                    d="M184 115C184 77.4446 152.555 48 115 48"
                    stroke="white"
                    strokeWidth="6"
                    fill="black"
                />
                <path
                    d="M105 48C65.7878 48 35 78.5 35 120"
                    stroke="white"
                    strokeWidth="6"
                    fill="black"
                />
                <path
                    d="M94.8913 67.2728 C82.1598 70.7823 59.5 83.5 56.5 106.5"
                    stroke="white"
                    strokeWidth="6"
                    fill="black"
                />
                <path
                    d="M162.949 106.566 C155.006 76.9218 124.535 59.3298 94.8913 67.2728"
                    stroke="white"
                    strokeWidth="6"
                    fill="black"
                />
                <path
                    d="M123.5 175.5C153.144 167.557 170.892 136.21 162.949 106.566"
                    stroke="white"
                    strokeWidth="6"
                    fill="black"
                />
                <path
                    d="M35 120 C35 148.842 50.5774 176.695 75 188"
                    stroke="white"
                    strokeWidth="6"
                    fill="black"
                />
                <path
                    d="M56.5 137C61.9066 156.4 75.6119 170.538 92.5 175.5"
                    stroke="white"
                    strokeWidth="6"
                    fill="black"
                />
                <path
                    d="M184 123.5C184 151.033 166.364 176.805 142.5 187.5"
                    stroke="white"
                    strokeWidth="6"
                    fill="black"
                />
                <path
                    d="M110 153 C127.673 153 142 138.673 142 121C142 103.327 127.673 89 110 89C92.3269 89 78 103.327 78 121C78 138.673 92.3269 153 110 153ZM106 158.792C87.8758 156.896 73.5428 142.26 72.1167 124H36V118H72.1167C73.6462 98.4155 90.0229 83 110 83C130.987 83 148 100.013 148 121C148 141.316 132.057 157.908 112 158.948V208H132V214H112L112 274H132V280H112H110H106L106 158.792ZM148 290H77L77 185H71L71 296H75H77H148V290ZM131 242H137V248H131V242ZM147 185L147 280H141L141 185H147ZM95 280L95 172H89L89 280H95ZM123 197H129V173H123V197Z"
                    fill="white"
                />
            </mask>
        </svg>
    );
};

export default {
    DynamicKey,
};
