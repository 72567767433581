import { Input } from '@chakra-ui/react';

const CustomInput = ({ name, register, withLabel = false, ...props }) => {
    return (
        <Input
            borderColor="gray.200"
            borderWidth="1px"
            borderRadius={1}
            w="100%"
            align="start"
            h={['40px', '40px', '40px', '60px']}
            px={2}
            fontSize={['small', 'small', 'small', 'medium']}
            pt={withLabel ? '30px' : '0px'}
            pb={withLabel ? '20px' : '0px'}
            name={name}
            {...props}
            {...register(name)}
        />
    );
};

export default CustomInput;
