import { Container, Flex, Image, Spacer, Text, VStack } from '@chakra-ui/react';
import WhiteLogo from '../images/whiteLogo.svg';
import { useViewport } from '../utils';
import { termsText } from './termsAndConditionsText';

function TermsAndConditions() {
    const { isMobile } = useViewport();

    return (
        <Container
            w="100%"
            h="100vh"
            maxW="initial"
            p={0}
            backgroundColor="white"
        >
            <Flex
                direction={['column', 'column', 'column', 'row']}
                w="100%"
                h="100%"
            >
                <Flex
                    direction="column"
                    backgroundColor="brand.red"
                    w={['100%', '100%', '100%', '30%']}
                    p={10}
                    pt={[10, 10, 10, 40]}
                    h="100%"
                >
                    {isMobile && (
                        <>
                            <Text
                                fontWeight={700}
                                fontSize="xx-large"
                                color="white"
                            >
                                Terms & Conditions
                            </Text>
                            <Text mb={10} fontSize="large" color="white">
                                Last updated July 02, 2024
                            </Text>
                        </>
                    )}
                    <VStack alignItems="start">
                        <Text
                            fontSize={['larger', 'larger', 'larger', 'x-large']}
                            color="white"
                            fontWeight={500}
                            textDecoration="underline"
                        >
                            TERMS AND CONDITIONS
                        </Text>
                        <Text
                            fontSize={['larger', 'larger', 'larger', 'x-large']}
                            color="rgba(255, 255, 255, 0.8)"
                            fontWeight={500}
                        >
                            PRIVACY POLICY
                        </Text>
                        <Text
                            fontSize={['larger', 'larger', 'larger', 'x-large']}
                            color="rgba(255, 255, 255, 0.8)"
                            fontWeight={500}
                        >
                            PROMOTIONS
                        </Text>
                    </VStack>
                    <Spacer />
                    {!isMobile && (
                        <Image w={183} src={WhiteLogo} alignSelf="center" />
                    )}
                </Flex>
                <Flex
                    direction="column"
                    backgroundColor="white"
                    flex={1}
                    p={10}
                >
                    {!isMobile && (
                        <>
                            <Text
                                fontWeight={700}
                                fontSize="xxx-large"
                                color="brand.green"
                            >
                                Terms & Conditions
                            </Text>
                            <Text fontSize="large" color="brand.lightGray">
                                Last updated July 02, 2024
                            </Text>
                        </>
                    )}
                    <VStack
                        h={['100%', '100%', '100%', '80%']}
                        overflowY="auto"
                        gap={4}
                        mt={[3, 3, 3, 16]}
                        css={{
                            // Target scrollbar width and track
                            '&::-webkit-scrollbar': {
                                width: '6px', // Make scrollbar thinner
                            },
                            '&::-webkit-scrollbar-track': {
                                background: '#f1f1f1', // Background color of the scrollbar track
                            },
                            '&::-webkit-scrollbar-thumb': {
                                backgroundColor: '#E82C2A', // Scrollbar color
                                borderRadius: '10px', // Rounded scrollbar for smooth look
                            },
                            '&::-webkit-scrollbar-thumb:hover': {
                                backgroundColor: 'darkred', // Darker color on hover
                            },
                        }}
                    >
                        {termsText.map((terms) => (
                            <VStack gap={2} align="start" width={'100%'}>
                                <Text
                                    color="brand.red"
                                    fontSize="x-large"
                                    fontWeight={700}
                                >
                                    {terms.label}
                                </Text>

                                {terms.text}
                            </VStack>
                        ))}
                    </VStack>
                </Flex>
            </Flex>
        </Container>
    );
}

export default TermsAndConditions;
