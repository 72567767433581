import { Box, Stack, Text, useRadio, useRadioGroup } from '@chakra-ui/react';

const CustomRadio = (props) => {
    const { getInputProps, getRadioProps } = useRadio(props);

    const input = getInputProps();
    const checkbox = getRadioProps();

    return (
        <Box as="label">
            <input {...input} />
            <Box
                {...checkbox}
                cursor="pointer"
                borderWidth="1px"
                borderRadius="full"
                boxShadow="md"
                _checked={{
                    bg: '#A8B8BC',
                    borderColor: 'brand.green',
                }}
                _hover={{
                    bg: '#A8B8BC',
                }}
                px={5}
                py={3}
                transition="background-color 0.2s ease"
                display="flex"
                alignItems="center"
            >
                <Box
                    borderWidth="2px"
                    borderColor="brand.green"
                    borderRadius="full"
                    padding="8px"
                    mr={4}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    minWidth="35px"
                    height="35px"
                >
                    <Text fontWeight="bold">{props.children[0]}</Text>
                </Box>
                <Text fontWeight={500}>{props.children[1]}</Text>
            </Box>
        </Box>
    );
};

const StyledRadioButtons = ({ options, onChange }) => {
    const questionIndexes = ['A', 'B', 'C', 'D', 'E'];
    const { getRootProps, getRadioProps } = useRadioGroup({
        name: 'quiz',
        defaultValue: 'A',
        onChange: onChange,
    });

    const group = getRootProps();

    return (
        <Stack {...group} spacing={4}>
            {options.map((value, index) => {
                const radio = getRadioProps({ value });
                return (
                    <CustomRadio key={value} {...radio}>
                        {questionIndexes[index]}

                        {value}
                    </CustomRadio>
                );
            })}
        </Stack>
    );
};

export default StyledRadioButtons;
