import { useState, useEffect } from "react";

export const useViewport = () => {
  const [width, setWidth] = useState(window.innerWidth);

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  const isMobile = width <= 992;
  const isLandscape = width <= 850 && width >= 768;
  const isPortrait = width <= 480;

  return {
    isLandscape,
    isPortrait,
    isMobile,
    width,
  }
};

export default {
  useViewport,
};
