import { defineStyleConfig, extendTheme } from '@chakra-ui/react';

const Button = defineStyleConfig({
  baseStyle: {
    fontWeight: 500,
    textTransform: 'uppercase',
    borderRadius: 0,
  },
  sizes: {
    sm: {
      fontSize: 'sm',
      px: 4,
      py: 3,
    },
    md: {
      fontSize: 'md',
      px: 6,
      py: 4,
    },
  },
  variants: {
    red: {
      bg: 'brand.red',
      color: 'white',
    },
    green: {
      bg: 'brand.green',
      color: 'white',
      textTransform: 'none',
    },
    white: {
      bg: 'white',
      color: 'brand.green',
      textTransform: 'none',
    },
    greenBorder: {
      bg: 'white',
      color: 'brand.green',
      textTransform: 'none',
      borderColor: 'brand.green',
      borderWidth: 1,
    },
  },
  defaultProps: {
    size: 'md',
    variant: 'red',
  },
});

const redDoorTheme = extendTheme({
  components: {
    Button,
    Select: {
      baseStyle: {
        field: {
          color: 'gray.500',
        },
      },
    },
    Alert: {
      variants: {
        custom: {
          container: {
            bg: '#003644', // Custom background color
            color: 'white', // Custom text color
            borderRadius: '8px',
            padding: '16px',
          },
        },
      },
    },
  },
  colors: {
    brand: {
      green: '#003644',
      red: '#E82C2A',
      video: '#DB2B42',
      gray: '#EAEFF3',
      lightGray: '#ADB1B1',
      gradientBlue: '#0087AA',
      gradientBlue2: '#003644',
      greens: {
        50: 'gray',
        100: '#003644',
        200: '#003644',
        300: '#003644',
        400: '#003644',
        500: '#003644',
      },
    },
  },
});

export default redDoorTheme;
