import {
    Box,
    Button,
    Flex,
    Image,
    Text,
    Tooltip,
    useClipboard,
    useColorModeValue,
    useToast,
} from '@chakra-ui/react';
import { format } from 'date-fns';
import React from 'react';

import copy from '../../../../images/Copy.svg';
import expiry from '../../../../images/expiry.svg';
import { useViewport } from '../../../../utils';

// Coupon structure:
// {
//     reward: 'Free Coffee',
//     code: 'REDDOOR123',
//     expiry: 'OCT 31',
// }

const Coupon = ({ code, expirationDate, reward }) => {
    const bgColor = useColorModeValue('white', 'gray.800');
    const textColor = useColorModeValue('black', 'white');
    const { onCopy } = useClipboard(code);

    const toast = useToast();
    const { width } = useViewport();

    if (!reward) return null; // Prevent rendering if reward is not provided

    const formattedExpirationDate = expirationDate
        ? format(new Date(expirationDate), 'MMM dd yyyy').toUpperCase()
        : 'MM dd';

    const rewardWidth = () => {
        switch (true) {
            case width >= 1720:
                return '220px';
            case width >= 1224:
                return '210px';
            case width >= 800:
                return '210px';
            case width >= 600:
                return '220px';
            case width >= 520:
                return '210px';
            case width >= 480:
                return '170px';
            case width >= 420:
                return '200px';
            default:
                return '140px';
        }
    };

    return (
        <Box
            width={['85%', '80%', '370px']}
            height="auto"
            bg={bgColor}
            borderRadius="md"
            borderStyle="dashed"
            borderWidth="2px"
            borderColor="gray.300"
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            p={1}
            mx="auto"
            id="coupon-container"
        >
            {/* Left Side */}
            <Flex
                alignItems="center"
                justifyContent={'center'}
                width={['50%', '50%', '40%', '40%', '40%']}
                id="coupon-expiry-container"
            >
                <Box
                    bg="red.500"
                    color="white"
                    borderRadius="sm"
                    px={1}
                    display="flex"
                    flexDirection="column"
                    mr={4}
                    flex="1"
                    id="coupon-expiry-box"
                >
                    <Flex my={2}>
                        <Flex>
                            <img src={expiry} width={'28px'} height={'28px'} />
                        </Flex>

                        <Flex
                            alignItems="center"
                            flexDirection="column"
                            flex="auto"
                        >
                            <Text fontSize={['xs', 'sm']} ml={2}>
                                {expirationDate ? 'Expires' : 'No'}
                            </Text>
                            <Text
                                fontSize={['xs', 'xs']}
                                ml={2}
                                fontWeight="bold"
                            >
                                {expirationDate
                                    ? formattedExpirationDate
                                    : 'EXPIRY'}
                            </Text>
                        </Flex>
                    </Flex>
                </Box>
            </Flex>

            <Flex
                flexDirection="column"
                alignItems="center"
                width={rewardWidth()}
            >
                <Tooltip
                    label={reward}
                    color={'black'}
                    bg="#ffffffa6"
                    placement="top"
                    sx={{
                        backdropFilter: 'blur(5px)',
                        borderRadius: 'md',
                        color: 'black',
                        padding: '8px',
                    }}
                >
                    <Text
                        isTruncated={true}
                        align={'center'}
                        width={rewardWidth()}
                        maxWidth={rewardWidth()}
                        fontSize={['md', 'lg']}
                        fontWeight="bold"
                        color={textColor}
                    >
                        {reward}
                    </Text>
                </Tooltip>
                <Tooltip
                    label={code}
                    color={'black'}
                    bg="#ffffffa6"
                    placement="top"
                    sx={{
                        backdropFilter: 'blur(5px)',
                        borderRadius: 'md',
                        color: 'black',
                        padding: '8px',
                    }}
                >
                    <Button
                        size="sm"
                        mr={2}
                        maxWidth={rewardWidth()}
                        variant="outline"
                        onClick={() => {
                            onCopy();
                            toast({
                                title: 'Code copied to clipboard.',
                                description: code,
                                status: 'info',
                                duration: 9000,
                                isClosable: true,
                            });
                        }}
                    >
                        <Text isTruncated={true}>{code}</Text>
                        <Image ml={1} src={copy} />
                    </Button>
                </Tooltip>
            </Flex>
        </Box>
    );
};

export default Coupon;
